import type { CvResultType } from 'requests/types/results/cv.type'

/**
 * Skill Object
 */
export default class Skill {
    name: string

    elements: string[]

    constructor({ name = '', elements = [] }: NonNullable<CvResultType['skills']>['0'] = {}) {
        this.name = name
        this.elements = elements
    }
}
