import classNames from 'classnames'
import { Navigate, Outlet } from 'react-router-dom'
import { Icon, MessageBar, Text } from '@fluentui/react'
import useLayout from 'pages/_layout-private/layout-private.hook'
import styles from 'pages/_layout-private/layout-private.module.scss'
import LogoImage from 'assets/img/logo.png'
import Tabs from 'pages/_layout-private/components/tabs/tabs.component'
import HelpModal from 'pages/_layout-private/components/help-modal/help-modal.component'
import { Loader } from 'components/elements'

export type LayoutProps = {
    /** OnRefresh */
    onRefresh: () => void
    /** IsAppLoaded */
    isAppLoaded: boolean
}

/**
 * App layout
 */
export default function LayoutPrivate({
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    onRefresh,
    isAppLoaded = false,
}: LayoutProps): React.ReactElement {
    const {
        messageBar,
        setMessageBar,
        onSubmit,
        isModalVisible,
        setIsModalVisible,
        isHomeMode,
        setIsHomeMode,
        lastSynchroDate,
        isAuthenticated,
        onLogout,
    } = useLayout()

    if (!isAuthenticated) {
        return (
            <Navigate
                to={{
                    pathname: '/login',
                }}
            />
        )
    }

    return (
        <>
            <div className={classNames(styles['layout-private'])}>
                {isAppLoaded ? (
                    <>
                        <header className={classNames(styles['layout-private-header'])}>
                            <img
                                src={LogoImage}
                                alt="Nextoo"
                            />
                            <form
                                className={classNames(styles['layout-private-header-input-container'])}
                                onSubmit={onSubmit}
                            >
                                {isHomeMode && (
                                    <>
                                        <input
                                            type="search"
                                            name="query"
                                            autoComplete="off"
                                            spellCheck="false"
                                        />
                                        <button type="submit">
                                            <Icon iconName="Search" />
                                        </button>
                                    </>
                                )}
                            </form>
                            <div className={classNames(styles['layout-private-header-button-container'])}>
                                {isHomeMode && (
                                    <button
                                        type="button"
                                        onClick={() => setIsModalVisible(true)}
                                        title="Aide"
                                    >
                                        <Icon iconName="Info" />
                                    </button>
                                )}
                                <button
                                    type="button"
                                    onClick={() => setIsHomeMode(prev => !prev)}
                                    title={isHomeMode ? 'Paramètres' : 'Accueil'}
                                >
                                    <Icon iconName={isHomeMode ? 'Settings' : 'Home'} />
                                </button>
                                <button
                                    type="button"
                                    onClick={onLogout}
                                    title="Se déconnecter"
                                >
                                    <Icon iconName="SignOut" />
                                </button>
                            </div>
                        </header>
                        <br />
                        <div className={classNames(styles['tab-container'])}>
                            <Tabs mode={isHomeMode ? 'home' : 'setting'} />
                            {!isHomeMode && lastSynchroDate && (
                                <Text className={classNames(styles['last-synchro'])}>
                                    <time title="Date de la dernière synchronisation des CVs">
                                        {lastSynchroDate?.toLocaleString('fr-fr')}
                                    </time>
                                </Text>
                            )}
                        </div>
                        <hr />
                        <br />
                        {messageBar.isDisplayed && (
                            <>
                                <MessageBar
                                    messageBarType={messageBar.type}
                                    isMultiline={false}
                                    truncated
                                    onDismiss={() => setMessageBar({ isDisplayed: false })}
                                >
                                    {messageBar?.message ?? ''}
                                </MessageBar>
                                <br />
                            </>
                        )}
                        <Outlet />
                    </>
                ) : (
                    <Loader />
                )}
            </div>
            <HelpModal
                isVisible={isModalVisible}
                setIsVisible={setIsModalVisible}
            />
        </>
    )
}
