import { MessageBarType } from '@fluentui/react'
import { useCallback } from 'react'
import { useDispatch } from 'store'
import { setMessageBar } from 'store/slices/common.slice'
import { QUERY_CANCELLED, REQUEST_ABORTED } from 'types/messages'
import { signOut } from 'store/slices/user.slice'
import type Exception from 'requests/models/exceptions/exception.model'

export type UseHandleExceptionReturns = {
    /** HandleException */
    handleException: (exception: Exception<unknown>) => void
}

/**
 * UseHandleException
 */
export default function useHandleException(): UseHandleExceptionReturns {
    const dispatch = useDispatch()

    const handleException = useCallback(
        /**
         * HandleException
         * @param exception Exception
         */
        (exception: Exception<unknown>) => {
            switch (exception.statusCode) {
                // If we end up here, it's mean that the 401 was thrown on refresh, so access and refresh tokens are expired
                case 401:
                    dispatch(
                        setMessageBar({
                            isDisplayed: true,
                            message: exception.message,
                            type: MessageBarType.error,
                        }),
                    )
                    dispatch(signOut({ isWithLogoutApi: false }))
                    break
                default:
                    if (![QUERY_CANCELLED, REQUEST_ABORTED].includes(exception.message)) {
                        dispatch(
                            setMessageBar({
                                isDisplayed: true,
                                message: exception.message,
                                type: MessageBarType.error,
                            }),
                        )
                    }

                    break
            }
        },
        [dispatch],
    )

    return {
        handleException,
    }
}
