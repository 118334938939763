import { useMutation, useQuery } from 'react-query'
import { getCancelToken } from 'requests/fetch'
import { getAllSynonyms, upsertSynonym } from 'requests/handlers/synonyms.handler'
import { QUERY_CANCELLED } from 'types/messages'
import type { UseMutationOptions, UseQueryOptions } from 'react-query'
import type { Exception } from 'requests/models/exceptions'
import type { Synonym } from 'requests/models/objects'
import type { SynonymErrorFieldResultType } from 'requests/types/results/synonym.type'

/**
 * UseGetAllSynonyms
 * @param params params
 * @param options options
 */
export function useGetAllSynonyms(options?: UseQueryOptions<Synonym, Exception<SynonymErrorFieldResultType>>) {
    const cancelToken = getCancelToken()

    return useQuery<Synonym, Exception<SynonymErrorFieldResultType>>(
        ['synonyms'],
        () => {
            const promise = getAllSynonyms({ cancelToken })

            promise.cancel = () => cancelToken.cancel(QUERY_CANCELLED)

            return promise
        },
        options,
    )
}

/**
 * UseUpdateSynonym
 * @param options Options
 */
export function useUpdateSynonym(
    options?: UseMutationOptions<
        Synonym,
        Exception<SynonymErrorFieldResultType>,
        {
            /** Synonym */
            data?: Synonym
        }
    >,
) {
    const cancelToken = getCancelToken()

    return useMutation<
        Synonym,
        Exception<SynonymErrorFieldResultType>,
        {
            /** Synonym */
            data?: Synonym
        }
    >(({ data }) => {
        const promise = upsertSynonym({ data, cancelToken })

        promise.cancel = () => cancelToken.cancel(QUERY_CANCELLED)

        return promise
    }, options)
}
