import { MessageBarType } from '@fluentui/react'
import { createSlice } from '@reduxjs/toolkit'
import type { Middleware, PayloadAction } from '@reduxjs/toolkit'

/**
 * Common middlewares, must have "getDefaultMiddleware"
 */
const commonMiddleware: Middleware[] = []

export type PayloadMessageBarType = {
    /** Is message bar display ? */
    isDisplayed: boolean
    /** MessageBarType */
    type?: MessageBarType
    /** Message to show ? */
    message?: string
}
export type PayloadModalType = {
    /** Is modal displayed ? */
    isDisplayed: boolean
    /** Title */
    title?: string
    /** Subtitle */
    subTitle?: string
    /** Callback when clicking "Yes" button */
    callback?: () => void
    /** JSX content */
    content?: React.ReactElement
}

export type CommonStateType = {
    /** Messagebar */
    messageBar: PayloadMessageBarType
    /** Modal */
    modal: PayloadModalType
    /** Query */
    query: {
        /** Q */
        q: string | null
        /** Date */
        date: Date | null
    }
}

/**
 * Common Slice
 */
const commonSlice = createSlice({
    name: 'common',
    initialState: {
        messageBar: {
            isDisplayed: false,
            type: undefined,
            message: undefined,
        },
        modal: {
            isDisplayed: false,
            title: '',
            subTitle: '',
            callback: () => null,
            content: undefined,
        },
        query: {
            q: null,
            date: null,
        },
    } as CommonStateType,
    reducers: {
        /**
         * Set MessageBar
         * @param state state
         * @param action action
         */
        setMessageBar: (state, action: PayloadAction<PayloadMessageBarType>) => {
            switch (action.payload.type) {
                case MessageBarType.error:
                    // eslint-disable-next-line no-param-reassign, no-nested-ternary
                    action.payload.message = action.payload.message
                        ? typeof action.payload.message !== 'object'
                            ? action.payload.message
                            : JSON.stringify(action.payload.message)
                        : 'Une erreur est survenue'
                    break
                case MessageBarType.blocked:
                    // eslint-disable-next-line no-param-reassign, no-nested-ternary
                    action.payload.message = action.payload.message
                        ? typeof action.payload.message !== 'object'
                            ? action.payload.message
                            : JSON.stringify(action.payload.message)
                        : "Vous n'êtes pas autorisé à faire ceci"
                    break
                case MessageBarType.success:
                    // eslint-disable-next-line no-param-reassign, no-nested-ternary
                    action.payload.message = action.payload.message
                        ? typeof action.payload.message !== 'object'
                            ? action.payload.message
                            : JSON.stringify(action.payload.message)
                        : "L'opération s'est correctement déroulée."
                    break
                default:
                    break
            }
            // eslint-disable-next-line no-param-reassign
            state.messageBar = action.payload
        },
        /**
         * Set Modal
         * @param state state
         * @param action action
         */
        setModal: (state, action: PayloadAction<PayloadModalType>) => {
            // eslint-disable-next-line no-param-reassign
            state.modal = action.payload
        },
        /**
         * Set query
         * @param state state
         * @param action action
         */
        setQuery: (state, action: PayloadAction<CommonStateType['query']>) => {
            // eslint-disable-next-line no-param-reassign
            state.query = action.payload
        },
    },
})

const { setMessageBar, setModal, setQuery } = commonSlice.actions
const commonReducer = commonSlice.reducer

export {
    setMessageBar,
    setModal,
    setQuery, // Reducers, used to call actions
    commonReducer, // All reducers, used to create store
    commonMiddleware, // Middleware
}
