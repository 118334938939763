import classNames from 'classnames'
import { Helmet as Head } from 'react-helmet'
import { Text } from '@fluentui/react'
import useSkills from 'pages/index/components/tabs/skills/skills.hook'
import styles from 'pages/index/components/tabs/skills/skills.module.scss'
import { IDLE, PENDING, RESOLVED } from 'types/status'
import { Loader } from 'components/elements'
import SkillsRow from 'pages/index/components/tabs/skills/components/skills-row/skills-row.component'
import DownloadButton from 'pages/index/components/tabs/skills/components/download-button/download-button.component'
import HelpText from 'pages/index/components/others/help-text/help-text.component'

/**
 * Skills page
 */
export default function Skills(): React.ReactElement {
    const { items, status, results, categoriesOpen, setCategoriesOpen, columns, selectedNameIndex, isMobile } = useSkills()

    return (
        <>
            <Head>
                <title>Compétences - Nextoo</title>
            </Head>

            <main className={classNames(styles.skills)}>
                <Text>{results}</Text>
                {status === IDLE && (
                    <Text>
                        Veuillez effectuer une recherche
                        <br />
                        <br />
                        <br />
                        <HelpText hasEndText />
                    </Text>
                )}
                {status === PENDING && <Loader />}
                <br />
                <br />
                {status === RESOLVED && selectedNameIndex > -1 && (
                    <>
                        <hr />
                        <div className={styles['skills-container']}>
                            <div className={styles['skills-container-left']}>
                                <Text
                                    variant="large"
                                    block
                                >
                                    Catégories
                                </Text>
                                {columns.map((score, i) => (
                                    <button
                                        key={`button_${score.name}`}
                                        type="button"
                                        className={classNames({
                                            [styles['is-active']]: categoriesOpen[i],
                                        })}
                                        onClick={() =>
                                            setCategoriesOpen(prevCategoriesOpen => {
                                                if (!prevCategoriesOpen[i]) {
                                                    if (!isMobile) {
                                                        window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
                                                    }
                                                    return prevCategoriesOpen.map((_, y) => y === i)
                                                }
                                                return prevCategoriesOpen
                                            })
                                        }
                                    >
                                        {score.name}
                                    </button>
                                ))}
                            </div>
                            <div className={styles['skills-container-right']}>
                                <div className={styles['skills-container-right-header']}>
                                    <Text
                                        variant="large"
                                        block
                                    >
                                        Editeurs et technologies
                                    </Text>
                                    <Text block>{columns[selectedNameIndex]?.elements?.length ?? [0]} technologies</Text>
                                </div>
                                <div className={styles['skills-table-container']}>
                                    <table className={styles['skills-table']}>
                                        <colgroup>
                                            <col />
                                            {columns[selectedNameIndex]?.elements?.map(element => (
                                                <col key={`col_${element.name}`} />
                                            ))}
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th aria-label="Empty" />
                                                {columns[selectedNameIndex]?.elements?.map(element => (
                                                    <th
                                                        key={`th_${element.name}`}
                                                        title={element.name}
                                                    >
                                                        <span>{element.name}</span>
                                                    </th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {items?.map(item => (
                                                <SkillsRow
                                                    key={`tr_${item.lastName}_${item.firstName}_${item.agency}`}
                                                    item={item}
                                                    categoriesOpen={categoriesOpen}
                                                />
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <br />
                                <br />
                                <DownloadButton />
                            </div>
                        </div>
                    </>
                )}
            </main>
        </>
    )
}
