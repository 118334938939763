import { Icon, List, Text } from '@fluentui/react'
import classNames from 'classnames'
import TooltipName from 'pages/index/components/others/tooltip-name/tooltip-name.component'
import styles from 'pages/index/components/tabs/map/components/agency/agency.module.scss'
import type { Cv } from 'requests/models/objects'

export type AgencyProps = {
    /** Agency */
    agency: string
    /** Items */
    items: Cv[]
}

/**
 * Agency page
 */
export default function Agency({ items = [], agency }: AgencyProps): React.ReactElement {
    return (
        <div className={classNames(styles.agency)}>
            <Text
                block
                variant="mediumPlus"
            >
                <Icon iconName="MapPin" /> {agency.toLowerCase().toFirstLetterUppercase()}
            </Text>
            <List
                items={items}
                renderedWindowsAhead={6}
                renderedWindowsBehind={6}
                // eslint-disable-next-line react/no-unstable-nested-components
                onRenderCell={(item, index) => (
                    <div
                        key={`item_${index}_${item?.lastName}_${item?.firstName}`}
                        className={classNames(styles['agency-cv'])}
                    >
                        <TooltipName
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            item={item!}
                        >
                            <Text block>
                                <a
                                    href={item?.fileUrl}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {item?.lastName || ''} {item?.firstName || ''}
                                </a>
                            </Text>
                        </TooltipName>
                        <Text block>
                            <a href={`mailto:${item?.email}`}>{item?.email || ''}</a>
                            <a href={`tel:${item?.phone}`}>{item?.phone || ''}</a>
                        </Text>
                    </div>
                )}
            />
        </div>
    )
}
