import { useCallback, useEffect, useState } from 'react'
import { useForceUpdate, useHandleException, useMediaQueries, usePrevious, useRouter } from 'hooks'
import { useGetLastSynchro } from 'requests/hooks/cvs.hook'
import { useDispatch, useSelector } from 'store'
import { setMessageBar, setQuery } from 'store/slices/common.slice'
import { TABS } from 'pages/_layout-private/components/tabs/tabs.component'
import { signOut } from 'store/slices/user.slice'
import type { Location } from 'react-router-dom'
import type { CommonStateType, PayloadMessageBarType } from 'store/slices/common.slice'

export type UsePrivateLayoutReturns = {
    /** Location */
    location: Location
    /** IsMobile */
    isMobile: boolean
    /** MessageBar */
    messageBar: CommonStateType['messageBar']
    /** SetMessageBar */
    setMessageBar: (params: PayloadMessageBarType) => void
    /** OnSubmit */
    onSubmit: (ev: React.FormEvent<HTMLFormElement>) => void
    /** IsModalVisible */
    isModalVisible: boolean
    /** SetIsModalVisible */
    setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>
    /** IsHomeMode */
    isHomeMode: boolean
    /** SetIsHomeMode */
    setIsHomeMode: React.Dispatch<React.SetStateAction<boolean>>
    /** LastSynchroDate */
    lastSynchroDate?: Date
    /** IsAuthenticated */
    isAuthenticated: boolean
    /** OnLogout */
    onLogout: () => void
}

/**
 * UseLayoutPagePrivate
 */
export default function useLayoutPrivate(): UsePrivateLayoutReturns {
    const { handleException } = useHandleException()
    const { location } = useRouter()
    const { isMobile } = useMediaQueries()
    const dispatch = useDispatch()
    const messageBar = useSelector(state => state.common.messageBar)
    const me = useSelector(state => state.user.me)
    const isAuthenticated = useSelector(state => state.user.isAuthenticated)
    const { forceUpdate } = useForceUpdate()

    const [isModalVisible, setIsModalVisible] = useState(false)
    const [isHomeMode, setIsHomeMode] = useState(TABS.find(tab => tab.hash === location.hash)?.mode === 'home' || !location.hash)

    const { data: lastSynchroDate } = useGetLastSynchro({
        onError(err) {
            handleException(err)
        },
        enabled: !isHomeMode && !!me.userId,
    })

    const onSubmit = useCallback(
        (ev: React.FormEvent<HTMLFormElement>) => {
            ev.preventDefault()
            dispatch(setMessageBar({ isDisplayed: false }))
            dispatch(
                setQuery({
                    date: new Date(),
                    q: (ev.target as HTMLFormElement).query?.value || '',
                }),
            )
        },
        [dispatch],
    )

    const onLogout = useCallback(() => {
        dispatch(signOut({ isWithLogoutApi: true }))
    }, [dispatch])

    const prevMessageBar = usePrevious(messageBar)

    // Force update on message change
    useEffect(() => {
        if (messageBar?.message !== prevMessageBar?.message) {
            forceUpdate()
        }
    }, [forceUpdate, messageBar, prevMessageBar])

    // Update isHomeMode with navigation
    useEffect(() => {
        setIsHomeMode(prevIsHomeMode => {
            const isNewVal = TABS.find(tab => tab.hash === location.hash)?.mode === 'home' || !location.hash
            if (prevIsHomeMode !== isNewVal) {
                return isNewVal
            }
            return prevIsHomeMode
        })
    }, [location.hash])

    return {
        location,
        isMobile,
        messageBar,
        setMessageBar: params => dispatch(setMessageBar(params)),
        onSubmit,
        isModalVisible,
        setIsModalVisible,
        isHomeMode,
        setIsHomeMode,
        lastSynchroDate,
        isAuthenticated,
        onLogout,
    }
}
