import { useCallback } from 'react'
import { useHandleException } from 'hooks'
import { useGetSkillsFileCvs } from 'requests/hooks/cvs.hook'
import { useSelector } from 'store'
import HandleBlob from 'utils/others/blob'
import type { StatusType } from 'types/status'

export type DownloadButtonReturns = {
    /** On download file */
    onClickDownload: React.MouseEventHandler<HTMLButtonElement>
    /** File status */
    status: StatusType
}

/**
 * DownloadButton
 */
export default function useDownloadButton(): DownloadButtonReturns {
    const { handleException } = useHandleException()
    const query = useSelector(state => state.common.query)

    const {
        refetch: refetchFile,
        status,
        remove,
    } = useGetSkillsFileCvs(
        { ...query },
        {
            onError(err) {
                handleException(err)
            },
            onSuccess(data) {
                HandleBlob.download(data, 'matrice-de-competences.xlsx')
            },
            enabled: false,
        },
    )

    /**
     * On click download
     */
    const onClickDownload = useCallback(() => {
        remove()
        refetchFile()
    }, [refetchFile, remove])

    return {
        onClickDownload,
        status,
    }
}
