import type { IDropdownOption } from '@fluentui/react'

/**
 * Handle multiple choice dropdown
 * @param selectedItems All selected items
 * @param item Item clicked
 * @returns Selected items
 */
export default function getUpdatedList<T>(selectedItems: T[], item: IDropdownOption): T[] {
    const newSelectedItems = [...selectedItems]
    if (item.selected) {
        newSelectedItems.push(item.key as T)
    } else {
        const currIndex = newSelectedItems.indexOf(item.key as T)
        if (currIndex > -1) {
            newSelectedItems.splice(currIndex, 1)
        }
    }
    return newSelectedItems
}

/**
 * Handle multiple choice dropdown
 * @param selectedItems All selected items
 * @param itemKey Item key clicked
 * @returns Selected items
 */
export function getUpdatedListByKey<T>(selectedItems: T[], itemKey: number): T[] {
    const newSelectedItems = [...selectedItems]
    const currIndex = newSelectedItems.indexOf(itemKey as T)

    if (currIndex === -1) {
        newSelectedItems.push(itemKey as T)
    } else {
        newSelectedItems.splice(currIndex, 1)
    }

    return newSelectedItems
}
