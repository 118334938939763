import classNames from 'classnames'
import { Helmet as Head } from 'react-helmet'
import { List, Text } from '@fluentui/react'
import React from 'react'
import styles from 'pages/index/components/tabs/errors/errors.module.scss'
import useErrors from 'pages/index/components/tabs/errors/errors.hook'
import { PENDING } from 'types/status'
import { Loader } from 'components/elements'

/**
 * Errors page
 */
export default function Errors(): React.ReactElement {
    const { items, status, results } = useErrors()

    return (
        <>
            <Head>
                <title>Erreurs - Nextoo</title>
            </Head>

            <main className={classNames(styles.errors)}>
                <Text>{results}</Text>
                {status === PENDING && <Loader />}
                <br />
                <br />
                <List
                    items={items.filter(item => !!item.validation?.errors?.length || !!item.validation?.warnings?.length)}
                    renderedWindowsAhead={6}
                    renderedWindowsBehind={6}
                    onShouldVirtualize={() => false}
                    // eslint-disable-next-line react/no-unstable-nested-components
                    onRenderCell={(item, index) => (
                        <React.Fragment key={`item${index}`}>
                            <Text
                                as="h2"
                                variant="large"
                                block
                            >
                                <a
                                    href={item?.fileUrl}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {item?.lastName} {item?.firstName} ({item?.agency})
                                </a>
                            </Text>
                            {!!item?.validation?.errors?.length && (
                                <>
                                    <Text block>
                                        <b>Erreurs :</b>
                                    </Text>
                                    <ul>
                                        {item?.validation?.errors.map((error, j) => (
                                            // eslint-disable-next-line react/no-array-index-key
                                            <li key={`error${j}`}>{error}</li>
                                        ))}
                                    </ul>
                                </>
                            )}
                            {!!item?.validation?.warnings.length && (
                                <>
                                    <Text block>
                                        <b>Avertissements :</b>
                                    </Text>
                                    <ul>
                                        {item?.validation?.warnings.map((warning, j) => (
                                            // eslint-disable-next-line react/no-array-index-key
                                            <li key={`warning${j}`}>{warning}</li>
                                        ))}
                                    </ul>
                                </>
                            )}
                            <br />
                        </React.Fragment>
                    )}
                />
            </main>
        </>
    )
}
