import getFetch from 'requests/fetch'
import Exception from 'requests/models/exceptions/exception.model'
import { User } from 'requests/models/objects'
import type { GetFetchParams } from 'requests/fetch'
import type { UserApiResultResultType, UserResultType } from 'requests/types/results/user.type'

/**
 * Post Users Login
 */
export async function postUsersLogin({
    data,
    cancelToken,
}: {
    /** Data */
    data: User
    /** CancelToken */
    cancelToken?: GetFetchParams['cancelToken']
}): Promise<User> {
    try {
        const { data: res } = await getFetch<UserApiResultResultType>({
            url: ['users', 'login'],
            method: 'POST',
            data: { users: data },
            cancelToken,
        })

        return new User(res.users)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
        throw new Exception(error?.response?.data ?? error)
    }
}

/**
 * Post Users Logout
 */
export async function postUsersLogout({
    data,
    cancelToken,
}: {
    /** Data */
    data: {
        /** RefreshToken */
        refreshToken: string
    }
    /** CancelToken */
    cancelToken?: GetFetchParams['cancelToken']
}): Promise<User> {
    try {
        const { data: res } = await getFetch<UserApiResultResultType>({
            url: ['users', 'logout'],
            method: 'POST',
            data: { users: data },
            cancelToken,
        })

        return new User(res.users)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
        throw new Exception(error?.response?.data ?? error)
    }
}

/**
 * Get Me
 */
export async function getMe({
    cancelToken,
}: {
    /** CancelToken */
    cancelToken?: GetFetchParams['cancelToken']
} = {}): Promise<UserResultType> {
    try {
        const { data: res } = await getFetch<UserApiResultResultType>({
            url: ['users', 'me'],
            cancelToken,
        })

        // We do not serialize usertype here, so we can save it to cache with raw data. Usefull if user model was updated but app is not yet.
        return res.users ?? {}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
        throw new Exception(error?.response?.data ?? error)
    }
}

/**
 * Patch Users Refresh
 */
export async function patchUsersMeRefreshToken({
    data,
    cancelToken,
}: {
    /** Data */
    data: {
        /** RefreshToken */
        refreshToken: string
    }
    /** CancelToken */
    cancelToken?: GetFetchParams['cancelToken']
}): Promise<User> {
    try {
        const { data: res } = await getFetch<UserApiResultResultType>({
            url: ['users', 'refresh'],
            method: 'PATCH',
            data: { users: data },
            cancelToken,
        })

        return new User(res.users)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
        throw new Exception(error?.response?.data ?? error)
    }
}
