import type { CvResultType } from 'requests/types/results/cv.type'

/**
 * Experience Object
 */
export default class Experience {
    startDate: Date | undefined

    endDate: Date | undefined

    months: number

    title: string

    clientName: string

    projectName: string

    content: string

    tools: string[]

    constructor({
        startDate = '',
        endDate = '',
        months = 0,
        title = '',
        clientName = '',
        projectName = '',
        content = '',
        tools = [],
    }: NonNullable<CvResultType['experiences']>['0'] = {}) {
        this.startDate = startDate ? new Date(startDate) : undefined
        this.endDate = endDate ? new Date(endDate) : undefined
        this.months = months
        this.title = title
        this.clientName = clientName
        this.projectName = projectName
        this.content = content
        this.tools = tools
    }
}
