import { MessageBarType } from '@fluentui/react'
import { useCallback, useState } from 'react'
import { useHandleException } from 'hooks'
import { useGetAllSynonyms, useUpdateSynonym } from 'requests/hooks/synonyms.hook'
import { Synonym } from 'requests/models/objects'
import { useDispatch, useSelector } from 'store'
import { setMessageBar } from 'store/slices/common.slice'
import { PENDING, REJECTED, RESOLVED } from 'types/status'
import type { StatusType } from 'types/status'
import type { User } from 'requests/models/objects'
import type { Exception } from 'requests/models/exceptions'
import type { SynonymErrorFieldResultType } from 'requests/types/results/synonym.type'

export type UseSynonymsReturns = {
    /** Item */
    item: Synonym
    /** Status */
    status: StatusType
    /** Exception */
    exception: Exception<SynonymErrorFieldResultType> | null
    /** OnSubmit */
    onSubmit: (ev: React.FormEvent<HTMLFormElement>) => void
    /** HandleChange */
    handleChange: (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    /** MeProfile */
    meProfile: User['profile']
}

/**
 * UseSynonymsPage
 */
export default function useSynonyms(): UseSynonymsReturns {
    const meProfile = useSelector(state => state.user.me?.profile || 'consultant')
    const { handleException } = useHandleException()
    const dispatch = useDispatch()

    const [item, setItem] = useState(new Synonym())
    const [status, setStatus] = useState<StatusType>(PENDING)

    /** Get synonyms data */
    useGetAllSynonyms({
        onSuccess(data) {
            setItem(data)
            setStatus(RESOLVED)
        },
        onError(error) {
            setStatus(REJECTED)
            handleException(error)
        },
        enabled: true,
    })

    /** Update synonyms data */
    const { error: exception, mutate: updateSynonyms } = useUpdateSynonym()

    /** Update item */
    const onSubmit = useCallback(
        (ev: React.FormEvent<HTMLFormElement>) => {
            ev.preventDefault()
            setStatus(PENDING)
            updateSynonyms(
                { data: item },
                {
                    onSuccess(data) {
                        setItem(data)
                        setStatus(RESOLVED)
                        dispatch(
                            setMessageBar({
                                isDisplayed: true,
                                type: MessageBarType.success,
                                message: 'La liste à bien été mise à jour.',
                            }),
                        )
                    },
                    onError(error) {
                        setStatus(REJECTED)
                        handleException(error)
                    },
                },
            )
        },
        [dispatch, handleException, item, updateSynonyms],
    )

    /** Handlechange */
    const handleChange = useCallback((ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setItem((ev.target as HTMLTextAreaElement).value)
    }, [])

    return {
        item,
        status,
        exception,
        onSubmit,
        handleChange,
        meProfile,
    }
}
