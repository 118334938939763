import Base from 'requests/models/objects/_base.model'
import type { UserResultType } from 'requests/types/results/user.type'

/**
 * User
 */
export default class User extends Base {
    userId: string

    email: string

    username: string

    profile: 'admin' | 'manager' | 'consultant'

    password: string

    accessToken: string

    refreshToken: string

    constructor({
        userId = '',
        email = '',
        username = '',
        profile = 'consultant',
        password = '',
        accessToken = '',
        refreshToken = '',
        ...data
    }: UserResultType = {}) {
        super(data)
        this.userId = userId
        this.email = email
        this.username = username
        this.profile = profile
        this.password = password
        this.accessToken = accessToken
        this.refreshToken = refreshToken
    }
}
