import { createSlice } from '@reduxjs/toolkit'
import { postUsersLogout } from 'requests/handlers/users.handler'
import { User } from 'requests/models/objects'
import env from 'types/env'
import { STORAGE_TOKEN_KEY, STORAGE_TOKEN_REFRESH_KEY, STORAGE_USER_KEY } from 'types/others'
import { setQuery } from 'store/slices/common.slice'
import type { Middleware, PayloadAction } from '@reduxjs/toolkit'

/**
 * User middlewares, must have "getDefaultMiddleware"
 */
const userMiddleware: Middleware[] = []

export type PayloadInitType = {
    /** User */
    me: User
}
export type PayloadSingInType = {
    /** AccessToken */
    accessToken: string
    /** RefreshToken */
    refreshToken: string
}
export type PayloadSingOutType = {
    /** Is with logout in API. If `false`, mean that all tokens are expired so we can't call API to clean some stuff */
    isWithLogoutApi: boolean
}
export type PayloadMeType = {
    /** User */
    me: User
}

export type UserStateType = {
    /** Is user authenticated? */
    isAuthenticated: boolean
    /** User informations */
    me: User
}

/**
 * User Slice
 */
const userSlice = createSlice({
    name: 'user',
    initialState: {
        isAuthenticated: !!localStorage.getItem(STORAGE_TOKEN_KEY),
        me: new User(),
    } as UserStateType,
    reducers: {
        /**
         * Sign in
         * @param state state
         * @param action action
         */
        signIn: (state, action: PayloadAction<PayloadSingInType>) => {
            localStorage.setItem(STORAGE_TOKEN_KEY, action.payload.accessToken)
            localStorage.setItem(STORAGE_TOKEN_REFRESH_KEY, action.payload.refreshToken)
            // eslint-disable-next-line no-param-reassign
            state.isAuthenticated = true
        },
        /**
         * Sign out
         * @param state state
         * @param action action
         */
        signOut: (state, action: PayloadAction<PayloadSingOutType>) => {
            if (action.payload?.isWithLogoutApi) {
                ;(async () => {
                    try {
                        // Add token, because at the time of the execution, it might already have been deleted from storage
                        await postUsersLogout({
                            data: {
                                refreshToken: localStorage.getItem(STORAGE_TOKEN_REFRESH_KEY) || '',
                            },
                        })
                    } catch (error) {} // eslint-disable-line no-empty
                })()
            }

            ;(async () => {
                // eslint-disable-next-line import/no-cycle
                ;(await import('../index')).default.dispatch(setQuery({ q: null, date: null }))
            })()

            caches
                .delete(`${env.BASE_STORAGE_KEY}-api`)
                // eslint-disable-next-line no-console
                .catch(console.error)

            localStorage.removeItem(STORAGE_TOKEN_KEY)
            localStorage.removeItem(STORAGE_TOKEN_REFRESH_KEY)
            localStorage.removeItem(STORAGE_USER_KEY)
            // LocalStorage.removeItem(STORAGE_PARAM_KEY)
            // LocalStorage.removeItem(STORAGE_UPDATED_AT_KEY)
            // eslint-disable-next-line no-param-reassign
            state.isAuthenticated = false
        },
        /**
         * Init
         * @param state state
         * @param action action
         */
        init: (state, action: PayloadAction<PayloadInitType>) => {
            localStorage.setItem(STORAGE_USER_KEY, JSON.stringify(action.payload?.me))

            // eslint-disable-next-line no-param-reassign
            state.me = action.payload?.me
        },
        /**
         * SetMe
         * @param state state
         * @param action action
         */
        setMe: (state, action: PayloadAction<PayloadMeType>) => {
            localStorage.setItem(STORAGE_USER_KEY, JSON.stringify(action.payload?.me))

            // eslint-disable-next-line no-param-reassign
            state.me = action.payload?.me
        },
    },
})

const { init, signIn, signOut, setMe } = userSlice.actions
const userReducer = userSlice.reducer

export {
    init,
    signIn,
    signOut,
    setMe, // Reducers, used to call actions
    userReducer, // All reducers, used to create store
    userMiddleware, // Middleware
}
