import { useState, useEffect } from 'react'
import { createPortal } from 'react-dom'

export type PortalProps = {
    /** Element of the portal container */
    element?: keyof HTMLElementTagNameMap
    /** ClassName */
    className?: HTMLElement['className']
    /** Children */
    children: React.ReactNode
}

/**
 * Portal
 */
export default function Portal({ element = 'div', className = 'root-portal', children }: PortalProps): React.ReactElement {
    const [container] = useState(() => {
        const el = document.createElement(element)
        el.classList.add(className)
        return el
    })

    useEffect(() => {
        document.body.appendChild(container)
        return () => {
            document.body.removeChild(container)
        }
    }, [container])

    return createPortal(children, container)
}
