import classNames from 'classnames'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Icon } from '@fluentui/react'
import { useEffect } from 'react'
import styles from 'pages/_layout-private/components/tabs/tabs.module.scss'
import { usePrevious } from 'hooks'
import { useDispatch, useSelector } from 'store'
import { setMessageBar, setQuery } from 'store/slices/common.slice'
import type { User } from 'requests/models/objects'

export type TabsType = {
    /** Title */
    title: string
    /** IconName */
    iconName: string
    /** Hash */
    hash: string
    /** Mode */
    mode: 'home' | 'setting'
    /** Profiles */
    profiles: User['profile'][]
}[]

export const TABS = [
    {
        iconName: 'Search',
        title: 'Tous',
        hash: '#all',
        mode: 'home',
        profiles: ['consultant', 'manager', 'admin'],
    },
    {
        iconName: 'Nav2DMapView',
        title: 'Carte',
        hash: '#map',
        mode: 'home',
        profiles: ['consultant', 'manager', 'admin'],
    },
    {
        iconName: 'WaitlistConfirm',
        title: 'Compétences',
        hash: '#skills',
        mode: 'home',
        profiles: ['manager', 'admin'],
    },
    {
        iconName: 'SearchCalendar',
        title: 'Disponibilités',
        hash: '#availability',
        mode: 'home',
        profiles: ['manager', 'admin'],
    },
    {
        iconName: 'DocumentApproval',
        title: 'Validation',
        hash: '#validation',
        mode: 'setting',
        profiles: ['consultant', 'manager', 'admin'],
    },
    {
        iconName: 'CheckList',
        title: 'Synonymes',
        hash: '#synonyms',
        mode: 'setting',
        profiles: ['consultant', 'manager', 'admin'],
    },
    {
        iconName: 'Error',
        title: 'Erreurs',
        hash: '#errors',
        mode: 'setting',
        profiles: ['manager', 'admin'],
    },
    {
        iconName: 'UserWarning',
        title: 'CV non trouvés',
        hash: '#not-found',
        mode: 'setting',
        profiles: ['manager', 'admin'],
    },
] as TabsType

export type TabsProps = {
    /** Mode */
    mode: TabsType[0]['mode']
}

/**
 * App layout
 */
export default function Tabs({ mode }: TabsProps): React.ReactElement {
    const location = useLocation()
    const prevHash = usePrevious(location.hash)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const me = useSelector(state => state.user.me)

    const prevMode = usePrevious(mode)

    // Clean messagebar on change tab
    useEffect(() => {
        if (location.hash !== prevHash && prevHash) {
            dispatch(setMessageBar({ isDisplayed: false }))
        }
    }, [dispatch, location.hash, prevHash])

    // Change tab when mode change
    useEffect(() => {
        if (mode !== prevMode && prevMode) {
            switch (mode) {
                case 'home':
                    dispatch(setQuery({ date: null, q: null }))
                    navigate({ hash: '#all' })
                    break
                case 'setting':
                    dispatch(setQuery({ date: null, q: null }))
                    navigate({ hash: '#validation' })
                    break
                default:
                    break
            }
        }
    }, [dispatch, mode, navigate, prevMode])

    return (
        <div className={classNames(styles.tabs)}>
            {TABS.filter(tab => tab.mode === mode && tab.profiles.includes(me.profile)).map((tab, i) => (
                <div
                    // eslint-disable-next-line react/no-array-index-key
                    key={i}
                    className={classNames(styles.tab, { [styles['is-selected']]: tab.hash === location.hash })}
                >
                    <Link to={{ hash: tab.hash }}>
                        <Icon iconName={tab.iconName} />
                        <span> {tab.title}</span>
                    </Link>
                </div>
            ))}
        </div>
    )
}
