import { Dialog, DialogContent, DialogType } from '@fluentui/react'
import styles from 'pages/_layout-private/components/help-modal/help-modal.module.scss'
import HelpText from 'pages/index/components/others/help-text/help-text.component'

export type HelpModalProps = {
    /** IsVisible */
    isVisible: boolean
    /** SetIsVisible */
    setIsVisible: (newVal: boolean) => void
}

/**
 * App layout
 */
export default function HelpModal({ isVisible, setIsVisible }: HelpModalProps): React.ReactElement {
    if (!isVisible) {
        return null as never
    }

    return (
        <Dialog
            hidden={!isVisible}
            onDismiss={() => setIsVisible(false)}
            dialogContentProps={{
                type: DialogType.close,
                title: 'Aide à la recherche',
                closeButtonAriaLabel: 'Close',
                // SubText: '',
                showCloseButton: true,
            }}
            modalProps={{
                isBlocking: false,
                className: styles['help-modal'],
            }}
            minWidth="455px"
            maxWidth="555px"
        >
            <HelpText />
            <br />
            <br />
            <DialogContent
                title="A propos des rôles"
                styles={{ content: { margin: '0 -24px' }, inner: { display: 'none' } }}
            />
            <>
                L'ensemble des fonctionnalités de l'application repose sur des groupes AD.
                <br />
                <br />
                Pour avoir accès aux fonctionnalités, votre compte doit donc être positionné dans au moins un des groupes suivants :
                <ul style={{ listStyle: 'disc' }}>
                    <li style={{ margin: '0 0 0 1.5rem' }}>
                        <b>GG_nextoo_admin</b> : Permet d'accéder aux onglets : Tous, Carte, Compétences, Disponibilités, Validation,
                        Synonymes, Erreurs et CV non trouvés
                    </li>
                    <li style={{ margin: '0 0 0 1.5rem' }}>
                        <b>GG_nextoo_manager</b> : Permet d'accéder aux onglets : Tous, Carte, Compétences, Disponibilités, Validation,
                        Synonymes (en lecture seul), Erreurs et CV non trouvés
                    </li>
                    <li style={{ margin: '0 0 0 1.5rem' }}>
                        <b>GG_nextoo_consultant</b> : Permet d'accéder aux onglets : Tous, Carte, Validation et Synonymes (en lecture seul)
                    </li>
                </ul>
                <br />
                Si vous pensez ne pas avoir le bon rôle, vous pouvez demander au support informatique de vous ajouter dans le bon groupe AD
                :{' '}
                <a
                    href="https://support.nextaccess.fr/"
                    target="_blank"
                    rel="noreferrer"
                >
                    support.nextaccess.fr
                </a>
                .
                <br />
                <br />
                Pour pouvoir accéder aux CV en cliquant sur les liens présents dans les différentes pages, vous devez avoir accès au Drive
                Google : ADM_CV.
            </>
        </Dialog>
    )
}
