import { useCallback, useState } from 'react'
import { useHandleChange, useHandleException, useRouter } from 'hooks'
import { usePostUsersLogin } from 'requests/hooks/users.hook'
import { User } from 'requests/models/objects'
import { useDispatch } from 'store'
import { signIn } from 'store/slices/user.slice'
import type { Exception } from 'requests/models/exceptions'
import type { UseHandleChangeReturns } from 'hooks/handle-change.hook'
import type { UserErrorFieldResultType } from 'requests/types/results/user.type'
import type { StatusType } from 'types/status'

export type UseLoginReturns = {
    /** Login */
    login: (ev: React.FormEvent<HTMLFormElement>) => void
    /** User */
    user: User
    /** ErrorField */
    exception: Exception<UserErrorFieldResultType> | null
    /** Status */
    status: StatusType
    /** HandleChange */
    handleChange: UseHandleChangeReturns['handleChange']
}

/**
 * UseLoginPage
 */
export default function useLogin(): UseLoginReturns {
    const dispatch = useDispatch()
    const { handleException } = useHandleException()
    const { navigate } = useRouter()

    const [user, setUser] = useState(new User({ email: '', password: '' }))

    const { handleChange } = useHandleChange(setUser, User)

    const {
        mutate: postUsersLogin,
        status,
        error: exception,
    } = usePostUsersLogin({
        onSuccess(data) {
            dispatch(signIn({ accessToken: data.accessToken || '', refreshToken: data.refreshToken || '' }))
        },
    })

    const login = useCallback(
        (ev: React.FormEvent<HTMLFormElement>) => {
            ev.preventDefault()
            postUsersLogin(
                { data: user },
                {
                    onSuccess() {
                        navigate('/')
                    },
                    onError(error) {
                        handleException(error)
                    },
                },
            )
        },
        [postUsersLogin, user, navigate, handleException],
    )

    return {
        login,
        user,
        status,
        handleChange,
        exception,
    }
}
