import ReactDOMClient from 'react-dom/client'
import 'styles/index.scss'
import { QueryClient, QueryClientProvider } from 'react-query'
import { initializeIcons } from '@fluentui/react/lib/Icons'
import { loadTheme } from '@fluentui/react'
import { Provider } from 'react-redux'
import App from 'app.component'
import { HistoryRouter } from 'components/routers'
import ErrorBoundary from 'pages/_error-boundary/error-boundary.component'
import store from 'store'
import 'requests/pretender'
import 'utils/prototypes'

/**
 * @see https://github.com/microsoft/fluentui/wiki/Using-icons
 */
initializeIcons()
/**
 * @see https://fabricweb.z5.web.core.windows.net/pr-deploy-site/refs/heads/master/theming-designer/index.html
 */
loadTheme({
    palette: {
        themePrimary: '#800080',
        themeLighterAlt: '#faf0fa',
        themeLighter: '#ebc5eb',
        themeLight: '#d998d9',
        themeTertiary: '#b347b3',
        themeSecondary: '#8f118f',
        themeDarkAlt: '#730073',
        themeDark: '#610061',
        themeDarker: '#470047',
        neutralLighterAlt: '#faf9f8',
        neutralLighter: '#f3f2f1',
        neutralLight: '#edebe9',
        neutralQuaternaryAlt: '#e1dfdd',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c6c4',
        neutralTertiary: '#a19f9d',
        neutralSecondary: '#605e5c',
        neutralPrimaryAlt: '#3b3a39',
        neutralPrimary: '#323130',
        neutralDark: '#201f1e',
        black: '#000000',
        white: '#ffffff',
    },
})

/** Query client */
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnMount: false,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
            retry: 0,
            cacheTime: 0,
            staleTime: 0,
        },
        mutations: {
            retry: 0,
        },
    },
})

// App container
const container = document.getElementById('root')

// Create a root
const root = ReactDOMClient.createRoot(container as HTMLElement)

root.render(
    <Provider store={store}>
        <ErrorBoundary>
            <HistoryRouter>
                <QueryClientProvider client={queryClient}>
                    <App />
                </QueryClientProvider>
            </HistoryRouter>
        </ErrorBoundary>
    </Provider>,
)
