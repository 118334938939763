import classNames from 'classnames'
import { Helmet as Head } from 'react-helmet'
import { DefaultButton, Text } from '@fluentui/react'
import useAvailability from 'pages/index/components/tabs/availability/availability.hook'
import styles from 'pages/index/components/tabs/availability/availability.module.scss'
import { IDLE, PENDING, REJECTED, RESOLVED } from 'types/status'
import { Loader } from 'components/elements'
import TooltipName from 'pages/index/components/others/tooltip-name/tooltip-name.component'
import { Dropdown } from 'components/inputs'
import Legend from 'pages/index/components/tabs/availability/components/legend/legend.component'
import HelpText from 'pages/index/components/others/help-text/help-text.component'
import type { IDropdownOption } from '@fluentui/react'
import type { Calendar } from 'requests/models/objects'

const DROPDOWN_OPTION: IDropdownOption[] = [
    // { key: 2, text: 'Vue 2 Semaines' },
    { key: 4, text: 'Vue 4 Semaines', data: { icon: 'RedEye' } },
    { key: 6, text: 'Vue 6 Semaines', data: { icon: 'RedEye' } },
    { key: 7, text: 'Vue 8 Semaines', data: { icon: 'RedEye' } },
]

/**
 * Get calendar size
 * @param index start index
 * @param calendars calendars
 */
const getCalendarSize = (index: number, calendars: Calendar[]): number => {
    let j = index

    while (j < calendars.length && calendars[j].name === calendars[index].name) {
        j += 1
        if (calendars[j - 1].date?.getDay() === 5) {
            // Break on friday
            break
        }
    }

    return j - index
}

/**
 * Availability page
 */
export default function Availability(): React.ReactElement {
    const { results, items, status, headers, tableContainerRef, selectedPeriodKey, onSelectedPeriodChange, period } = useAvailability()

    return (
        <>
            <Head>
                <title>Disponibilités - Nextoo</title>
            </Head>

            <main className={classNames(styles.availability)}>
                {status === REJECTED && <Text>{results}</Text>}
                {status === RESOLVED && (
                    <div className={classNames(styles['availability-header'])}>
                        <div className={classNames(styles['availability-header-left'])}>
                            <Text>{results}</Text>
                            <DefaultButton
                                text="Aujourd'hui"
                                onClick={() => tableContainerRef.current?.scrollTo({ left: 0, top: 0, behavior: 'smooth' })}
                            />
                            <Text
                                variant="large"
                                className={classNames(styles['availability-header-left-period'])}
                            >
                                {period}
                            </Text>
                        </div>
                        <div className={classNames(styles['availability-header-right'])}>
                            <Dropdown
                                selectedKey={selectedPeriodKey}
                                onChange={onSelectedPeriodChange}
                                options={DROPDOWN_OPTION}
                            />
                        </div>
                    </div>
                )}
                {status === IDLE && (
                    <Text>
                        Veuillez effectuer une recherche
                        <br />
                        <br />
                        <br />
                        <HelpText hasEndText />
                    </Text>
                )}
                {status === PENDING && <Loader />}
                <br />
                <br />
                {status === RESOLVED && (
                    <>
                        <div
                            className={classNames(styles['availability-table-container'])}
                            ref={tableContainerRef}
                        >
                            <table className={classNames(styles['availability-table'])}>
                                <colgroup>
                                    <col />
                                    {headers.subs.map((_, i) => (
                                        <col
                                            // eslint-disable-next-line react/no-array-index-key
                                            key={`col_${i}`}
                                        />
                                    ))}
                                </colgroup>
                                <thead>
                                    {headers.mains.length > 0 && (
                                        <tr>
                                            <th aria-label="Empty" />
                                            {headers.mains?.map(main => (
                                                <th
                                                    key={main}
                                                    colSpan={5}
                                                >
                                                    <Text>{main}</Text>
                                                </th>
                                            ))}
                                            <th aria-label="Empty" />
                                        </tr>
                                    )}
                                    {headers.subs.length > 0 && (
                                        <tr>
                                            <th aria-label="Empty" />
                                            {headers.subs?.map(sub => (
                                                <th key={sub}>
                                                    <Text>{sub}</Text>
                                                </th>
                                            ))}
                                            <th>
                                                Nbr.
                                                <br />
                                                Inter.
                                            </th>
                                        </tr>
                                    )}
                                </thead>
                                <tbody>
                                    {items?.map(item => {
                                        const intercoNbr =
                                            item.calendars
                                                ?.slice(0, selectedPeriodKey * 5)
                                                ?.filter(calendar => calendar.type === 'INTERCONTRAT')?.length ?? 0

                                        return (
                                            <tr key={`${item.lastName} ${item.firstName}`}>
                                                <td>
                                                    <TooltipName item={item}>
                                                        <Text>
                                                            {item.lastName} {item.firstName}
                                                        </Text>
                                                    </TooltipName>
                                                </td>
                                                {item.calendars?.slice(0, selectedPeriodKey * 5)?.map((calendar, y) => {
                                                    const size = getCalendarSize(y, item.calendars)

                                                    return (
                                                        <td
                                                            // eslint-disable-next-line react/no-array-index-key
                                                            key={`${item.lastName} ${item.firstName} ${y}`}
                                                            className={classNames(styles['availability-table-cell'], {
                                                                [styles['is-training']]: calendar.type === 'FORMATION',
                                                                [styles['is-intercontract']]: calendar.type === 'INTERCONTRAT',
                                                                [styles['is-not-invoiced']]: calendar.type === 'NON FACTURE',
                                                                [styles['is-internal']]: calendar.type === 'INTERNE',
                                                                [styles['is-option']]: calendar.type === 'OPTION',
                                                                [styles['is-support-only']]: calendar.type === 'SUPPORT UNIQUEMENT',
                                                                [styles['is-support']]: calendar.type === 'SUPPORT',
                                                                [styles['is-pre-sale']]: calendar.type === 'AVANT-VENTE',
                                                                [styles['is-holiday']]: calendar.type === 'CONGE',
                                                                [styles['is-client']]: calendar.type === 'CLIENT',
                                                            })}
                                                        >
                                                            {y - 1 >= 0 &&
                                                            item.calendars[y - 1].name === calendar.name &&
                                                            item.calendars[y - 1].date?.getDay() !== 5 ? null : (
                                                                <>
                                                                    <div
                                                                        className={classNames({
                                                                            [styles['is-one']]: size === 1,
                                                                            [styles['is-two']]: size === 2,
                                                                            [styles['is-three']]: size === 3,
                                                                            [styles['is-four']]: size === 4,
                                                                            [styles['is-five']]: size === 5,
                                                                        })}
                                                                        title={calendar.name}
                                                                    />
                                                                    <Text
                                                                        className={classNames({
                                                                            [styles['is-one']]: size === 1,
                                                                            [styles['is-two']]: size === 2,
                                                                            [styles['is-three']]: size === 3,
                                                                            [styles['is-four']]: size === 4,
                                                                            [styles['is-five']]: size === 5,
                                                                        })}
                                                                        title={calendar.name}
                                                                    >
                                                                        {calendar.name}
                                                                    </Text>
                                                                </>
                                                            )}
                                                        </td>
                                                    )
                                                })}
                                                <td
                                                    className={classNames(styles['availability-table-cell-interco'], {
                                                        [styles['has-interco']]: intercoNbr > 0,
                                                    })}
                                                >
                                                    <Text>{intercoNbr}</Text>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <br />
                        <Legend />
                    </>
                )}
            </main>
        </>
    )
}
