import { Transformer } from 'markmap-lib'
import { Markmap } from 'markmap-view'
import { useEffect, useMemo, useRef } from 'react'
import styles from 'pages/index/components/others/mindmap/mindmap.module.scss'
import { getColor } from 'utils/styles'
import type { Cv } from 'requests/models/objects'

export type MindMapProps = {
    /** Item */
    item: Cv
    /** Style */
    style?: React.CSSProperties
}

const findElementsByScore = ({
    value,
    scores,
}: {
    /** Score */
    value: number
    /** Scores */
    scores: Cv['scores']
}) =>
    scores
        .map(score => {
            if (score.elements.some(x => x.score === value)) {
                return `### ${score.name}\n${score.elements
                    .filter(x => x.score === value)
                    .map(x => `- ${x.name}\n`)
                    .join('')}`
            }
            return ''
        })
        .join('')

/**
 * MindMap
 */
export default function MindMap({ item, style = {} }: MindMapProps): React.ReactElement {
    // Ref for SVG element
    const refSvg = useRef<SVGSVGElement>(null)

    const content = useMemo(
        () => `
# ${item.firstName} ${item.lastName}

${['Expert *****', 'Confirmé ****', 'Experimenté ***', 'Connaissance **', 'Notion *']
    .map(
        (title, index) => `
## ${title}

${findElementsByScore({ value: 5 - index, scores: item.scores })}

`,
    )
    .join('')}
`,
        [item],
    )

    useEffect(() => {
        const subTitlePath: { [key: string]: string } = {}

        const markmap = Markmap.create(refSvg.current as SVGSVGElement, {
            color: node => {
                if (node.depth === 1) {
                    subTitlePath[node.content.replace('##', '').replaceAll('*', '').trim()] = node.state?.path ?? ''
                }

                if (node.state?.path === subTitlePath.Expert || node.state?.path?.startsWith(`${subTitlePath.Expert}.`)) {
                    return getColor('skill-color-background-five')
                }
                if (node.state?.path === subTitlePath['Confirmé'] || node.state?.path?.startsWith(`${subTitlePath['Confirmé']}.`)) {
                    return getColor('skill-color-background-four')
                }
                if (node.state?.path === subTitlePath['Experimenté'] || node.state?.path?.startsWith(`${subTitlePath['Experimenté']}.`)) {
                    return getColor('skill-color-background-three')
                }
                if (node.state?.path === subTitlePath.Connaissance || node.state?.path?.startsWith(`${subTitlePath.Connaissance}.`)) {
                    return getColor('skill-color-background-two')
                }
                if (node.state?.path === subTitlePath.Notion || node.state?.path?.startsWith(`${subTitlePath.Notion}.`)) {
                    return getColor('skill-color-background-one')
                }
                return getColor('dark-grey')
            },
        })

        const { root } = new Transformer().transform(content)
        markmap.setData(root)
        markmap.fit()
    }, [content])

    return (
        <svg
            ref={refSvg}
            className={styles.mindmap}
            style={style}
        />
    )
}
