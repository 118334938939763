import { useEffect, useRef } from 'react'

/**
 * Return previous value
 * {@link https://usehooks.com/usePrevious/}
 * @param value Value
 */
export default function usePrevious<T>(value: T): T {
    const ref = useRef<T>()

    useEffect(() => {
        ref.current = value
    })

    return ref.current as T
}
