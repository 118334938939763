import { useMemo } from 'react'
import { useHandleException } from 'hooks'
import { useGetErrorCvs } from 'requests/hooks/cvs.hook'
import { RESOLVED } from 'types/status'
import type { StatusType } from 'types/status'
import type { Cv } from 'requests/models/objects'

export type UseNotFoundReturns = {
    /** Items */
    items: Cv[]
    /** Status */
    status: StatusType
    /** Results */
    results?: string
}

/**
 * UseNotFoundPage
 */
export default function useNotFound(): UseNotFoundReturns {
    const { handleException } = useHandleException()

    const { data: items, status } = useGetErrorCvs(
        { hasDocumentFound: false },
        {
            onError(err) {
                handleException(err)
            },
        },
    )

    const results = useMemo(
        () => (status === RESOLVED ? `${new Intl.NumberFormat('fr-fr').format(items?.length || 0)} CV non trouvés` : ''),
        [items?.length, status],
    )

    return {
        items: items || [],
        status,
        results,
    }
}
