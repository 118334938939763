import type { CvResultType } from 'requests/types/results/cv.type'

/**
 * Calendar Object
 */
export default class Calendar {
    date?: Date

    name: string

    type:
        | 'FORMATION'
        | 'INTERCONTRAT'
        | 'NON FACTURE'
        | 'INTERNE'
        | 'OPTION'
        | 'SUPPORT UNIQUEMENT'
        | 'SUPPORT'
        | 'AVANT-VENTE'
        | 'CONGE'
        | 'CLIENT'

    constructor({ date, name = '', type = 'INTERCONTRAT' }: NonNullable<CvResultType['calendars']>[0] = {}) {
        this.date = date ? new Date(date) : undefined
        this.name = name
        this.type = type
    }
}
