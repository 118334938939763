import { useMemo, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import type { SkillsRowProps } from 'pages/index/components/tabs/skills/components/skills-row/skills-row.component'

export type SkillsRowReturns = {
    /** Item */
    isModalVisible: boolean
    /** SetIsModalVisible */
    setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>
    /** PortalId */
    portalId: string
    /** IsAllZeros */
    isAllZeros: boolean
}

export type SkillsRowParams = SkillsRowProps

/**
 * SkillsRow
 */
export default function useSkillsRow({ item, categoriesOpen }: SkillsRowParams): SkillsRowReturns {
    const [isModalVisible, setIsModalVisible] = useState(false)

    const portalId = useMemo(() => uuidv4(), [])

    const isAllZeros = item?.scores
        .filter((score, i) => categoriesOpen[i] && score.elements.length > 0)
        .every(score => score.elements.every(element => element.score === 0))

    return {
        isModalVisible,
        setIsModalVisible,
        portalId,
        isAllZeros,
    }
}
