import { Text } from '@fluentui/react'
import { Helmet as Head } from 'react-helmet'
import useNotFound from 'pages/404/404.hook'
import { Card } from 'components/containers'

/**
 * NotFound page
 */
export default function NotFound(): React.ReactElement {
    useNotFound()

    return (
        <>
            <Head>
                <title>404 - Nextoo</title>
            </Head>

            <main>
                <Card
                    title="Erreur"
                    iconName="Error"
                >
                    <Text
                        as="p"
                        block
                    >
                        La page n'a pas été trouvée
                    </Text>
                </Card>
            </main>
        </>
    )
}
