import type { CvResultType } from 'requests/types/results/cv.type'

/**
 * Validation Object
 */
export default class Validation {
    errors: string[]

    warnings: string[]

    constructor({ errors = [], warnings = [] }: NonNullable<CvResultType['validation']> = {}) {
        this.errors = errors
        this.warnings = warnings
    }
}
