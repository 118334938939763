import ScoreElement from 'requests/models/objects/score-element.model'
import type { CvResultType } from 'requests/types/results/cv.type'

/**
 * Score Object
 */
export default class Score {
    name: string

    elements: ScoreElement[]

    constructor({ name = '', elements = [] }: NonNullable<CvResultType['scores']>['0'] = {}) {
        this.name = name
        this.elements = elements?.map(x => new ScoreElement(x)) ?? []
    }
}
