import getFetch from 'requests/fetch'
import Exception from 'requests/models/exceptions/exception.model'
import { Cv } from 'requests/models/objects'
import type { GetFetchParams } from 'requests/fetch'
import type {
    CvsApiResultResultType,
    CvResultType,
    CvsApiResultResultsType,
    CvsSynchroDateResultType,
} from 'requests/types/results/cv.type'

export type SearchParamsType = {
    /** Query */
    q: string | null
}

/**
 * Get cvs
 */
export async function getAllCvs({
    params,
    cancelToken,
}: {
    /** Params */
    params: SearchParamsType
    /** CancelToken */
    cancelToken?: GetFetchParams['cancelToken']
}): Promise<Cv[]> {
    try {
        const { data: res } = await getFetch<CvsApiResultResultType>({
            url: ['cvs', 'all'],
            cancelToken,
            params,
        })

        return (res.cvs as CvResultType[])?.map(x => new Cv(x ?? {})) ?? []
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
        throw new Exception(error?.response?.data ?? error)
    }
}

/**
 * Get cvs
 */
export async function getMapCvs({
    params,
    cancelToken,
}: {
    /** Params */
    params: SearchParamsType
    /** CancelToken */
    cancelToken?: GetFetchParams['cancelToken']
}): Promise<Cv[]> {
    try {
        const { data: res } = await getFetch<CvsApiResultResultType>({
            url: ['cvs', 'map'],
            cancelToken,
            params,
        })

        return (res.cvs as CvResultType[])?.map(x => new Cv(x ?? {})) ?? []
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
        throw new Exception(error?.response?.data ?? error)
    }
}

/**
 * Get skills
 */
export async function getSkillsCvs({
    params,
    cancelToken,
}: {
    /** Params */
    params: SearchParamsType
    /** CancelToken */
    cancelToken?: GetFetchParams['cancelToken']
}): Promise<Cv[]> {
    try {
        const { data: res } = await getFetch<CvsApiResultResultType>({
            url: ['cvs', 'skills'],
            cancelToken,
            params,
        })

        return (res.cvs as CvResultType[])?.map(x => new Cv(x ?? {})) ?? []
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
        throw new Exception(error?.response?.data ?? error)
    }
}

/**
 * Get skills file
 */
export async function getSkillsFileCvs({
    params,
    cancelToken,
}: {
    /** Params */
    params: SearchParamsType
    /** CancelToken */
    cancelToken?: GetFetchParams['cancelToken']
}): Promise<Blob> {
    try {
        const { data: res } = await getFetch<string>({
            url: ['cvs', 'skills', 'file'],
            responseType: 'arraybuffer',
            cancelToken,
            params,
        })

        return new Blob([res])
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
        throw new Exception(error?.response?.data ?? error)
    }
}

/**
 * Get availability
 */
export async function getAvailabilityCvs({
    params,
    cancelToken,
}: {
    /** Params */
    params: SearchParamsType
    /** CancelToken */
    cancelToken?: GetFetchParams['cancelToken']
}): Promise<Cv[]> {
    try {
        const { data: res } = await getFetch<CvsApiResultResultType>({
            url: ['cvs', 'availability'],
            cancelToken,
            params,
        })

        return (res.cvs as CvResultType[])?.map(x => new Cv(x ?? {})) ?? []
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
        throw new Exception(error?.response?.data ?? error)
    }
}

/**
 * Post Validation
 */
export async function postValidationCv({
    file,
    cancelToken,
}: {
    /** File */
    file: globalThis.File
    /** CancelToken */
    cancelToken?: GetFetchParams['cancelToken']
}): Promise<Cv> {
    try {
        const formData = new FormData()
        formData.append('file', file)

        const { data: res } = await getFetch<CvsApiResultResultType>({
            url: ['cvs', 'validation'],
            cancelToken,
            method: 'POST',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data;',
            },
        })

        return new Cv(res.cvs ?? {})
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
        throw new Exception(error?.response?.data ?? error)
    }
}

export type ErrorParamsType = {
    /** HasDocumentFound */
    hasDocumentFound: boolean
}

/**
 * Get error
 */
export async function getErrorCvs({
    params,
    cancelToken,
}: {
    /** Params */
    params: ErrorParamsType
    /** CancelToken */
    cancelToken?: GetFetchParams['cancelToken']
}): Promise<Cv[]> {
    try {
        const { data: res } = await getFetch<CvsApiResultResultsType>({
            url: ['cvs', 'error'],
            cancelToken,
            params,
        })

        return (res.cvs as CvResultType[])?.map(x => new Cv(x ?? {})) ?? []
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
        throw new Exception(error?.response?.data ?? error)
    }
}

/**
 * Get synchro date
 */
export async function getSynchroDate({
    cancelToken,
}: {
    /** CancelToken */
    cancelToken?: GetFetchParams['cancelToken']
}): Promise<Date> {
    try {
        const { data: res } = await getFetch<CvsSynchroDateResultType>({
            url: ['cvs', 'last-synchro'],
            cancelToken,
        })

        return new Date(res['last-synchro'] || 0)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
        throw new Exception(error?.response?.data ?? error)
    }
}
