import { Text } from '@fluentui/react'
import classNames from 'classnames'
import styles from 'pages/index/components/tabs/validation/components/scores/scores.module.scss'

/**
 * Scores
 */
export default function Scores(): React.ReactElement {
    return (
        <Text className={styles.scores}>
            <br />
            <u>Information importante</u> : L'indexation Nextoo se fait sur le terme exact de la compétence.
            <br />
            <br />
            <p>Vous avez alors deux possibilités :</p>
            <ul>
                <li>Modifier le libellé de la compétence directement dans votre CV</li>
                <li>Soit enrichir le fichier des synonymes</li>
            </ul>
            <br />
            <p>
                Une fois le CV redéposé dans le répertoire ADM_CV du drive officielle, vos modifications seront prises en compte à la
                prochaine indexation (2 fois/jour).
            </p>
            <br />
            <ul>
                <li>
                    <b>0 étoile : </b>
                    aucune expérience et non présent dans le bloc compétence
                </li>
                <br />
                <li>
                    <b className={classNames(styles['is-one'])}>1 étoile</b> : Notion
                    <ul>
                        <li>moins de 6 mois d'expérience sur la techno ou juste présent dans le bloc compétence</li>
                    </ul>
                </li>
                <br />
                <li>
                    <b className={classNames(styles['is-two'])}>2 étoiles</b> : Connaissance
                    <ul>
                        <li>au moins 6 mois d'expérience sur la techno et au moins 2 expériences différentes</li>
                        <li>au moins 1 an d'expérience sur la techno et au moins 1 expérience</li>
                    </ul>
                </li>
                <br />
                <li>
                    <b className={classNames(styles['is-three'])}>3 étoiles</b> : Expérimenté
                    <ul>
                        <li>au moins 1 an d'expérience sur la techno et au moins 3 expériences différentes</li>
                        <li>au moins 2 ans d'expérience sur la techno et au moins 1 expérience</li>
                    </ul>
                </li>
                <br />
                <li>
                    <b className={classNames(styles['is-four'])}>4 étoiles</b> : Confirmé
                    <ul>
                        <li>au moins 2 ans d'expérience sur la techno et au moins 4 expériences différentes</li>
                        <li>au moins 3 ans d'expérience sur la techno et au moins 1 expérience</li>
                    </ul>
                </li>
                <br />
                <li>
                    <b className={classNames(styles['is-five'])}>5 étoiles</b> : Expert
                    <ul>
                        <li>au moins 3 ans d'expérience sur la techno et au moins 5 expériences différentes</li>
                    </ul>
                </li>
            </ul>
        </Text>
    )
}
