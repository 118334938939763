import useApp from 'app.hook'
import { Modal } from 'components/containers'

/**
 * App
 */
export default function App(): React.ReactElement {
    const { routes } = useApp()

    return (
        <>
            {routes}
            <Modal />
        </>
    )
}
