import { useMutation, useQuery } from 'react-query'
import { getMe, postUsersLogin } from 'requests/handlers/users.handler'
import { getCancelToken } from 'requests/fetch'
import { QUERY_CANCELLED } from 'types/messages'
import type { Exception } from 'requests/models/exceptions'
import type { User } from 'requests/models/objects'
import type { UserErrorFieldResultType, UserResultType } from 'requests/types/results/user.type'
import type { UseMutationOptions, UseQueryOptions } from 'react-query'

type PostUsersLoginParams = {
    /** Data */
    data: User
}

/**
 * UsePostUsersLogin
 * @param options Options
 */
export function usePostUsersLogin(options?: UseMutationOptions<User, Exception<UserErrorFieldResultType>, PostUsersLoginParams>) {
    const cancelToken = getCancelToken()

    return useMutation<User, Exception<UserErrorFieldResultType>, PostUsersLoginParams>(({ data }) => {
        const promise = postUsersLogin({ data, cancelToken })

        promise.cancel = () => cancelToken.cancel(QUERY_CANCELLED)

        return promise
    }, options)
}

/**
 * UseGetMe
 * @param options Options
 */
export function useGetMe(options?: UseQueryOptions<UserResultType, Exception<UserErrorFieldResultType>>) {
    const cancelToken = getCancelToken()

    return useQuery<UserResultType, Exception<UserErrorFieldResultType>>(
        ['users', 'me'],
        () => {
            const promise = getMe({ cancelToken })

            promise.cancel = () => cancelToken.cancel(QUERY_CANCELLED)

            return promise
        },
        options,
    )
}
