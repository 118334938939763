import colors from 'styles/exports/colors.module.scss'

/**
 * Get color from SCSS
 * @param key Color key
 * @returns Hexa color
 */
export default function getColor(
    key:
        | 'primary'
        | 'dark-primary'
        | 'white'
        | 'light-grey'
        | 'grey'
        | 'dark-grey'
        | 'link'
        | 'dark-link'
        | 'skill-color-font-one'
        | 'skill-color-background-one'
        | 'skill-color-font-two'
        | 'skill-color-background-two'
        | 'skill-color-font-three'
        | 'skill-color-background-three'
        | 'skill-color-font-four'
        | 'skill-color-background-four'
        | 'skill-color-font-five'
        | 'skill-color-background-five'
        | 'vailability-color-background-training'
        | 'vailability-color-font-training'
        | 'vailability-color-background-intercontract'
        | 'vailability-color-font-intercontract'
        | 'vailability-color-background-not-invoiced'
        | 'vailability-color-font-not-invoiced'
        | 'vailability-color-background-internal'
        | 'vailability-color-font-internal'
        | 'vailability-color-background-option'
        | 'vailability-color-font-option'
        | 'vailability-color-background-support-only'
        | 'vailability-color-font-support-only'
        | 'vailability-color-background-support'
        | 'vailability-color-font-support'
        | 'vailability-color-background-pre-sale'
        | 'vailability-color-font-pre-sale'
        | 'vailability-color-background-holiday'
        | 'vailability-color-font-holiday'
        | 'vailability-color-background-client'
        | 'vailability-color-font-client',
): string {
    return colors[key]
}
