import React from 'react'
import styles from 'components/elements/divider/divider.module.scss'

export type DividerProps = {
    /** Style */
    style?: object
}

/**
 * Card item
 */
export default function Divider({ style = {} }: DividerProps): React.ReactElement {
    return (
        <div
            className={styles.divider}
            style={style}
        >
            <span />
        </div>
    )
}
