import classNames from 'classnames'
import { Navigate, Outlet } from 'react-router-dom'
import { MessageBar } from '@fluentui/react'
import useLayoutPublic from 'pages/_layout-public/layout-public.hook'
import styles from 'pages/_layout-public/layout-public.module.scss'
import LogoImage from 'assets/img/logo.png'
import { Card } from 'components/containers'

/**
 * App layout
 */
export default function LayoutPublic(): React.ReactElement {
    const { isAuthenticated, messageBar, setMessageBar } = useLayoutPublic()

    if (isAuthenticated) {
        return <Navigate to="/" />
    }

    return (
        <div className={classNames(styles['layout-public'])}>
            <div className={classNames(styles['top-area'])} />
            <div className={classNames(styles['bottom-area'])} />
            <div className={classNames(styles['left-area'])} />
            <div className={classNames(styles['right-area'])} />
            <div className={classNames(styles['main-area'])}>
                <Card className="has-text-centered">
                    <img
                        src={LogoImage}
                        alt="Nextoo"
                    />
                    <br />
                    <br />
                    {messageBar.isDisplayed && (
                        <>
                            <MessageBar
                                messageBarType={messageBar.type}
                                isMultiline={false}
                                truncated
                                onDismiss={() => setMessageBar({ isDisplayed: false })}
                            >
                                {messageBar?.message ?? ''}
                            </MessageBar>
                            <br />
                        </>
                    )}
                    <Outlet />
                </Card>
            </div>
        </div>
    )
}
