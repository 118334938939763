import { useLocation, useNavigate } from 'react-router-dom'
import { useComponentDidMount } from 'hooks'

export type UseIndexReturns = {
    /** Hash */
    hash: string
}

/**
 * UseIndexPage
 */
export default function useIndex(): UseIndexReturns {
    const location = useLocation()
    const navigate = useNavigate()

    useComponentDidMount(() => {
        if (!location.hash) {
            navigate({ hash: '#all' })
        }
    })

    return {
        hash: location.hash,
    }
}
