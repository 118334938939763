import { useMemo } from 'react'
import { useHandleException } from 'hooks'
import { useGetAllCvs } from 'requests/hooks/cvs.hook'
import { useSelector } from 'store'
import { RESOLVED } from 'types/status'
import type { Cv } from 'requests/models/objects'
import type { StatusType } from 'types/status'

export type UseAllReturns = {
    /** Items */
    items: Cv[]
    /** Status */
    status?: StatusType
    /** Results */
    results: string
}

/**
 * UseAllPage
 */
export default function useAll(): UseAllReturns {
    const { handleException } = useHandleException()
    const query = useSelector(state => state.common.query)

    const { data: items, status } = useGetAllCvs(
        { ...query },
        {
            enabled: query.q !== null,
            onError(err) {
                handleException(err)
            },
        },
    )

    const results = useMemo(
        () => (status === RESOLVED ? `${new Intl.NumberFormat('fr-fr').format(items?.length || 0)} résultats` : ''),
        [items?.length, status],
    )

    return {
        items: items || [],
        status,
        results,
    }
}
