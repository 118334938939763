import { useEffect } from 'react'
import { DialogFooter, PrimaryButton, DefaultButton, DialogType, Dialog } from '@fluentui/react'
import { useDispatch, useSelector } from 'store'
import { setModal } from 'store/slices/common.slice'
import { usePrevious } from 'hooks'

/**
 * Modal
 */
export default function Modal() {
    const dispatch = useDispatch()
    const modal = useSelector(state => state.common.modal)

    const prevModal = usePrevious(modal)

    useEffect(() => {
        /** Scrool to top of modal on creation */
        if (prevModal?.isDisplayed !== modal?.isDisplayed && modal?.isDisplayed === true) {
            setTimeout(() => {
                const el = document.querySelector('.ms-Modal-scrollableContent')
                if (el) {
                    el.scrollTop = 0
                }
            }, 50)
        }
    }, [modal, prevModal?.isDisplayed])

    if (!modal.isDisplayed) {
        return null
    }

    return (
        <Dialog
            hidden={false}
            onDismiss={() =>
                dispatch(
                    setModal({
                        isDisplayed: false,
                        title: modal.title,
                        subTitle: modal.subTitle,
                        callback: modal.callback,
                        content: modal.content,
                    }),
                )
            }
            dialogContentProps={{
                type: DialogType.largeHeader,
                title: modal.title,
                subText: modal.subTitle,
            }}
            modalProps={{
                isBlocking: true,
            }}
            maxWidth="555px"
        >
            {modal.content}
            <DialogFooter>
                <DefaultButton
                    onClick={() =>
                        dispatch(
                            setModal({
                                isDisplayed: false,
                                title: modal.title,
                                subTitle: modal.subTitle,
                                callback: modal.callback,
                                content: modal.content,
                            }),
                        )
                    }
                    text="Annuler"
                />
                {modal.callback && typeof modal.callback === 'function' && (
                    <PrimaryButton
                        onClick={() => {
                            modal.callback?.()
                            dispatch(
                                setModal({
                                    isDisplayed: false,
                                    title: modal.title,
                                    subTitle: modal.subTitle,
                                    callback: modal.callback,
                                    content: modal.content,
                                }),
                            )
                        }}
                        text="Oui"
                    />
                )}
            </DialogFooter>
        </Dialog>
    )
}
