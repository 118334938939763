// eslint-disable-next-line max-len
// eslint-disable-next-line @typescript-eslint/consistent-type-definitions, @typescript-eslint/naming-convention, no-unused-vars, @typescript-eslint/no-unused-vars
declare interface String {
    /**
     * Set the first character only of string to uppercase
     */
    toFirstLetterUppercase(): string
}

// eslint-disable-next-line no-extend-native
String.prototype.toFirstLetterUppercase = function toFirstLetterUppercase(this: string) {
    if (!this) {
        return this
    }
    return this.charAt(0).toUpperCase() + this.slice(1)
}
