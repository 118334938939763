/* eslint-disable react/no-danger */
import classNames from 'classnames'
import { DirectionalHint, Icon, Text, TooltipDelay, TooltipHost } from '@fluentui/react'
import { useMemo } from 'react'
import styles from 'pages/index/components/tabs/all/components/card/card.module.scss'
import type { UseAllReturns } from 'pages/index/components/tabs/all/all.hook'

export type CardProps = {
    /** Item */
    item: NonNullable<UseAllReturns['items']>[0]
}

/**
 * Card
 */
export default function Card({ item }: CardProps): React.ReactElement {
    const cvUpdatedMonthAgo = useMemo(
        () =>
            item.fileUpdatedAt
                ? new Date().getMonth() - item.fileUpdatedAt.getMonth() + 12 * (new Date().getFullYear() - item.fileUpdatedAt.getFullYear())
                : 0,
        [item.fileUpdatedAt],
    )

    const skills = useMemo(
        () => new Intl.ListFormat('fr', { style: 'long', type: 'conjunction' }).format(item.skills.map(x => x.elements).flat()),
        [item.skills],
    )

    const nextIntercoDays = useMemo(() => {
        if (!item.calendars[1]?.date) {
            return ''
        }
        const diffTime = Math.abs(new Date().getTime() - item.calendars[1].date.getTime())
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
        return new Intl.RelativeTimeFormat('fr', { numeric: 'auto' }).format(diffDays, 'day').replace(/ jours| jour/g, 'j')
    }, [item.calendars])

    return (
        <article className={classNames(styles.card)}>
            <header className={classNames(styles['card-header'])}>
                <div className={classNames(styles['card-header-left'])}>
                    <div className={classNames(styles['card-header-left-top'])}>
                        <Text
                            as="h2"
                            variant="large"
                            block
                        >
                            <a
                                href={item.fileUrl}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Icon iconName="Contact" /> <span dangerouslySetInnerHTML={{ __html: item.lastName }} />{' '}
                                <span dangerouslySetInnerHTML={{ __html: item.firstName }} />
                            </a>
                        </Text>
                        <Text block>
                            <Icon iconName="MapPin" /> <span dangerouslySetInnerHTML={{ __html: item.agency }} />
                            {item.email && (
                                <>
                                    {' '}
                                    ●{' '}
                                    <a
                                        href={`mailto:${item.email}`}
                                        dangerouslySetInnerHTML={{ __html: item.email }}
                                        aria-label={item.email}
                                    />
                                </>
                            )}
                            {item.phone && (
                                <>
                                    {' '}
                                    ●{' '}
                                    <a
                                        href={`tel:${item.phone}`}
                                        dangerouslySetInnerHTML={{ __html: item.phone }}
                                        aria-label={item.phone}
                                    />
                                </>
                            )}
                        </Text>
                    </div>
                    <div className={classNames(styles['card-header-left-middle'])}>
                        <Text
                            block
                            as="h3"
                        >
                            <Icon iconName="FavoriteStar" /> Compétences
                        </Text>
                        <Text block>
                            {skills ? (
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: skills,
                                    }}
                                    title={skills}
                                />
                            ) : (
                                <i>Aucune donnée</i>
                            )}
                        </Text>
                    </div>
                    <div className={classNames(styles['card-header-left-bottom'])}>
                        <span className={classNames({ [styles['is-outdated']]: cvUpdatedMonthAgo > 2 })}>●</span>{' '}
                        <time title="Date de la dernière mise à jour du CV">
                            Mis à jour le : {item.fileUpdatedAt?.toLocaleDateString('fr-fr')}
                        </time>
                    </div>
                </div>
                <div className={classNames(styles['card-header-right'])}>
                    <div className={classNames(styles['card-header-right-top'])}>
                        <div className={classNames(styles['card-header-right-top-card'])}>
                            <Text
                                block
                                as="h3"
                            >
                                <Icon iconName="Calendar" /> Prochaine disponibilité
                            </Text>
                            <div className={classNames(styles['is-intercontract'], { [styles['has-not-date']]: !item.calendars[1]?.date })}>
                                <Text block>Interco</Text>
                                <Text block>
                                    {item.calendars[1]?.date && <>{nextIntercoDays}, le </>}
                                    {item.calendars[1]?.date?.toLocaleDateString('fr-fr') ?? 'Pas de dates'}
                                </Text>
                            </div>
                        </div>
                        <div className={classNames(styles['card-header-right-top-card'])}>
                            <Text
                                block
                                as="h3"
                            >
                                <Icon iconName="FavoriteStar" /> Mission du jour
                            </Text>
                            <div
                                className={classNames({
                                    [styles['is-training']]: item.calendars[0]?.type === 'FORMATION',
                                    [styles['is-intercontract']]: item.calendars[0]?.type === 'INTERCONTRAT',
                                    [styles['is-not-invoiced']]: item.calendars[0]?.type === 'NON FACTURE',
                                    [styles['is-internal']]: item.calendars[0]?.type === 'INTERNE',
                                    [styles['is-option']]: item.calendars[0]?.type === 'OPTION',
                                    [styles['is-support-only']]: item.calendars[0]?.type === 'SUPPORT UNIQUEMENT',
                                    [styles['is-support']]: item.calendars[0]?.type === 'SUPPORT',
                                    [styles['is-pre-sale']]: item.calendars[0]?.type === 'AVANT-VENTE',
                                    [styles['is-holiday']]: item.calendars[0]?.type === 'CONGE',
                                    [styles['is-client']]: item.calendars[0]?.type === 'CLIENT',
                                })}
                            >
                                <Text
                                    block
                                    title={item.calendars[0]?.name}
                                >
                                    {item.calendars[0]?.name ?? 'Pas de mission'}
                                </Text>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            {item.experiences?.length > 0 && (
                <>
                    <hr />
                    <div className={classNames(styles['card-experiences'])}>
                        {item.experiences.map((experience, index) => {
                            const tools = new Intl.ListFormat('fr', { style: 'long', type: 'conjunction' }).format(experience.tools)
                            return (
                                <div
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={index}
                                    className={classNames(styles['card-experience'])}
                                >
                                    <div className={classNames(styles['card-experience-header'])}>
                                        <Text
                                            as="h3"
                                            block
                                        >
                                            {experience.clientName ? (
                                                <span
                                                    dangerouslySetInnerHTML={{ __html: experience.clientName }}
                                                    title={experience.clientName}
                                                />
                                            ) : (
                                                <i>Aucune donnée</i>
                                            )}
                                        </Text>
                                        <span>●</span>
                                        <Text block>
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        experience.startDate
                                                            ?.toLocaleDateString('fr-fr', { month: 'long', year: 'numeric' })
                                                            .toFirstLetterUppercase() || '',
                                                }}
                                            />{' '}
                                            -{' '}
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        experience.endDate
                                                            ?.toLocaleDateString('fr-fr', { month: 'long', year: 'numeric' })
                                                            .toFirstLetterUppercase() || '',
                                                }}
                                            />{' '}
                                            <span>
                                                (
                                                <span dangerouslySetInnerHTML={{ __html: experience.months?.toString() || '0' }} /> mois)
                                            </span>
                                        </Text>
                                    </div>
                                    <div className={classNames(styles['card-experience-content'])}>
                                        <div>
                                            <Text block>Détails</Text>
                                            <TooltipHost
                                                content={
                                                    experience.content ? (
                                                        <span dangerouslySetInnerHTML={{ __html: experience.content }} />
                                                    ) : undefined
                                                }
                                                delay={TooltipDelay.medium}
                                                directionalHint={DirectionalHint.topCenter}
                                                className={classNames(styles['card-experience-content-tooltip'])}
                                                tooltipProps={{ maxWidth: '1000px' }}
                                            >
                                                <Text block>
                                                    {experience.content ? (
                                                        <span dangerouslySetInnerHTML={{ __html: experience.content }} />
                                                    ) : (
                                                        <i>Aucune donnée</i>
                                                    )}
                                                </Text>
                                            </TooltipHost>
                                        </div>
                                        <div>
                                            <Text block>Outils</Text>
                                            {tools ? (
                                                <span
                                                    dangerouslySetInnerHTML={{
                                                        __html: tools,
                                                    }}
                                                    title={tools}
                                                />
                                            ) : (
                                                <i>Aucune donnée</i>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </>
            )}
        </article>
    )
}
