import { useCallback } from 'react'
import { useHandleException } from 'hooks'
import { usePostValidationCv } from 'requests/hooks/cvs.hook'
import type Cv from 'requests/models/objects/cv.model'
import type { StatusType } from 'types/status'

export type UseValidationReturns = {
    /** Item */
    item: Cv | undefined
    /** Status */
    status: StatusType
    /** OnUpload */
    onUpload: (ev: React.ChangeEvent<HTMLInputElement>) => void
    /** OnClick */
    onClick: (ev: React.ChangeEvent<EventTarget>) => void
}

/**
 * UseValidationPage
 */
export default function useValidation(): UseValidationReturns {
    const { handleException } = useHandleException()

    const {
        data: item,
        status,
        mutate,
        reset,
    } = usePostValidationCv({
        onError(err) {
            handleException(err)
        },
    })

    const onUpload = useCallback(
        (ev: React.ChangeEvent<HTMLInputElement>) => {
            if (ev.target?.files?.[0]) {
                mutate({ file: ev.target?.files?.[0] })
            }
        },
        [mutate],
    )

    const onClick = useCallback(
        (ev: React.ChangeEvent<EventTarget>) => {
            // eslint-disable-next-line no-param-reassign
            ;(ev.target as HTMLInputElement).value = ''
            reset()
        },
        [reset],
    )

    return {
        item,
        status,
        onUpload,
        onClick,
    }
}
