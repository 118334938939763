import { useLayoutEffect, useRef, useState } from 'react'
import { Router } from 'react-router-dom'

import { createBrowserHistory } from 'history'
import type { BrowserRouterProps } from 'react-router-dom'

export type HistoryRouterProps = BrowserRouterProps

/**
 * History router
 * @example
 * import HistoryRouter from 'components/historyRouter'
 * ReactDOM.render(<HistoryRouter><App /></HistoryRouter>, document.getElementById('root'))
 */
export default function HistoryRouter({ basename, children, window: windowProps }: HistoryRouterProps): React.ReactElement {
    const historyRef = useRef(createBrowserHistory({ window: windowProps || window }))

    /** Handle current state of router */
    const [state, setState] = useState({
        action: historyRef.current?.action,
        location: historyRef.current?.location,
    })

    const prevLocation = useRef<Partial<Location>>({})

    useLayoutEffect(() => {
        const unlisten = historyRef.current?.listen(update => {
            setState(update)

            if (
                prevLocation.current.pathname !== update.location.pathname ||
                prevLocation.current.hash !== update.location.hash ||
                prevLocation.current.search !== update.location.search
            ) {
                window?.scrollTo(0, 0)
            }

            prevLocation.current.pathname = update.location.pathname
            prevLocation.current.hash = update.location.hash
            prevLocation.current.search = update.location.search
        })

        return () => {
            unlisten()
        }
    }, [])

    return (
        <Router
            basename={basename}
            navigationType={state.action}
            location={state.location}
            navigator={historyRef.current}
        >
            {children}
        </Router>
    )
}
