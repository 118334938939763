import type { ChildType, ExceptionType } from 'requests/types/exceptions/exception.type'

/**
 * 😺
 * ReduceInvalidFields
 * @param data Array of ChildType
 */
const reduceInvalidFields = (data: ChildType[]): unknown =>
    data?.reduce(
        (a, b) => ({
            ...a,
            [b.property as string]: b.children?.length ? reduceInvalidFields(b.children) : b.constraints,
        }),
        {},
    ) ?? undefined

/**
 * Exception
 */
export default class Exception<T> extends Error {
    statusCode?: number

    message: string

    invalidFields?: T

    constructor(data: ExceptionType) {
        super()

        const {
            statusCode,
            message,
            errors = [],
        } = !data || typeof data === 'string' || JSON.stringify(data) === '{}'
            ? {
                  message: typeof data === 'string' ? data : 'Something bad happened',
                  statusCode: 500,
              }
            : data

        this.statusCode = statusCode

        // 😺
        this.message = message as string

        // 😺
        this.invalidFields = errors?.[0]?.children ? (reduceInvalidFields(errors?.[0]?.children) as T) : undefined
    }
}
