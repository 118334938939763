import env from 'types/env'

/** Storage token key */
export const STORAGE_TOKEN_KEY = `${env.BASE_STORAGE_KEY}_token`
/** Storage token refresh key */
export const STORAGE_TOKEN_REFRESH_KEY = `${env.BASE_STORAGE_KEY}_token_refresh`
/** Storage user key */
export const STORAGE_USER_KEY = `${env.BASE_STORAGE_KEY}_user`
/** Storage param key */
export const STORAGE_PARAM_KEY = `${env.BASE_STORAGE_KEY}_param`
/** Storage updated at key */
export const STORAGE_UPDATED_AT_KEY = `${env.BASE_STORAGE_KEY}_updated_at`
/** Broadcast channel key */
export const BROADCAST_CHANNEL_KEY = `${env.BASE_STORAGE_KEY}_broadcast_channel`

/** Broadcast sw update */
export const BROADCAST_SW_UPDATE = 'broadcast_sw_update'
/** Broadcast error on fetch item */
export const BROADCAST_ITEM_FETCH_FAILED = 'broadcast_item_fetch_failed'

/** New item URL Id */
export const NEW = 'new'

/** Search param string */
export const SEARCH_STRING = 'string'
/** Search param is new */
export const SEARCH_IS_NEW = 'isNew'
/** Search param is new price */
export const SEARCH_IS_NEW_PRICE = 'isNewPrice'
/** Search param is favorite */
export const SEARCH_IS_FAVORITE = 'isFavorite'

/** Skip waiting for sw */
export const SKIP_WAITING = 'SKIP_WAITING'
