import { Circle as CircleMap, InfoBox } from '@react-google-maps/api'
import { Text } from '@fluentui/react'
import { useMemo } from 'react'
import { useMediaQueries } from 'hooks'

const AGENCIES = {
    ANGERS: { lat: 47.47341302382776, lng: -0.5520303079502276 },
    BORDEAUX_TOULOUSE: { lat: 44.864820161179935, lng: -0.5757132865148816 },
    BREST: { lat: 48.39580350183384, lng: -4.484394486423632 },
    // INDÉPENDANTS: { lat: 0, lng: 0 },
    LILLE: { lat: 50.63950209754725, lng: 3.057423069460496 },
    LYON: { lat: 45.76678763235121, lng: 4.837487896318322 },
    MONTPELLIER: { lat: 43.60885208368113, lng: 3.895907855783429 },
    NANTES: { lat: 47.22514438552267, lng: -1.5639597023279326 },
    // ORGA: { lat: 0, lng: 0 },
    PARIS: { lat: 48.86793856995866, lng: 2.3098418675641574 },
    RENNES: { lat: 48.1125027626143, lng: -1.6848109729374332 },
    TOULOUSE: { lat: 43.60049449056947, lng: 1.4547808557832296 },
} as const

export type CircleProps = {
    /** Agency */
    agency: keyof typeof AGENCIES
    /** Weight */
    total: number
}

/**
 * Circle page
 */
export default function Circle({ agency, total }: CircleProps): React.ReactElement | null {
    const { isTablet } = useMediaQueries()
    const radius = useMemo(() => {
        if (total < 5) {
            return 5000 * (5 / 2)
        }
        if (total > 25) {
            return 5000 * (25 / 2)
        }
        return 5000 * (total / 2)
    }, [total])

    if (!AGENCIES[agency]) {
        return null
    }

    return (
        <>
            <CircleMap
                center={AGENCIES[agency]}
                options={{
                    strokeColor: '#800080',
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: '#800080',
                    fillOpacity: 0.7,
                    radius,
                    clickable: false,
                }}
            />
            <InfoBox
                position={new google.maps.LatLng(AGENCIES[agency])}
                options={{
                    boxStyle: {
                        // Background: '#000',
                        textAlign: 'center',
                        width: '50px',
                        height: '50px',
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        color: 'white',
                    },
                    pixelOffset: new google.maps.Size(-25, -25),
                    closeBoxURL: '',
                }}
            >
                <Text
                    style={{
                        color: 'white',
                        fontSize: isTablet ? '8px' : '12px',
                    }}
                >
                    {total}
                </Text>
            </InfoBox>
        </>
    )
}
