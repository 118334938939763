import { Text } from '@fluentui/react'
import classNames from 'classnames'
import styles from 'pages/index/components/tabs/availability/components/legend/legend.module.scss'

const COLORS = [
    { className: 'is-training', name: 'Formation' },
    { className: 'is-intercontract', name: 'Intercontrat' },
    { className: 'is-not-invoiced', name: 'Non facturé' },
    { className: 'is-internal', name: 'Interne' },
    { className: 'is-option', name: 'Option' },
    { className: 'is-support-only', name: 'Support uniquement' },
    { className: 'is-support', name: 'Support' },
    { className: 'is-pre-sale', name: 'Avant-Vente' },
    { className: 'is-holiday', name: 'Congé' },
    { className: 'is-client', name: 'Client' },
].sort((a, b) => a.name.localeCompare(b.name))

/**
 * Legend
 */
export default function Legend() {
    return (
        <div className={styles.legend}>
            <Text
                variant="mediumPlus"
                block
            >
                Légende:
            </Text>
            <div className={classNames(styles['legend-items'])}>
                {COLORS.map(color => (
                    <div
                        key={color.name}
                        className={classNames(styles['legend-item'], styles[color.className])}
                    >
                        {color.name}
                    </div>
                ))}
            </div>
        </div>
    )
}
