import { useCallback, useState } from 'react'

export type UseForceUpdateReturns = {
    /** ForceUpdate */
    forceUpdate: () => void
}

/**
 * UseForceUpdate
 */
export default function useForceUpdate(): UseForceUpdateReturns {
    const [, updateState] = useState()
    const forceUpdate = useCallback(() => updateState({} as never), [])

    return {
        forceUpdate,
    }
}
