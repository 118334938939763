import { Icon, Text } from '@fluentui/react'
import styles from 'pages/index/components/others/help-text/help-text.module.scss'

export type HelpTextProps = {
    /** HasEndText */
    hasEndText?: boolean
}

/**
 * HelpText
 */
export default function HelpText({ hasEndText = false }: HelpTextProps): React.ReactElement {
    return (
        <div className={styles['help-text']}>
            <Text block>
                Pour faire une recherche, vous pouvez simplement écrire les mots clés de votre recherche puis cliquez sur l'icône{' '}
                <em>
                    <Icon iconName="Search" />
                </em>
                .
            </Text>
            <br />
            <Text block>Vous pouvez également effectuer une recherche plus poussée :</Text>
            <br />
            <Text block>
                <b>Filtres balises</b>
            </Text>
            <Text>Relatives aux missions :</Text>
            <ul>
                <li>
                    <Text>
                        <em>client:XXX</em> : dans les clients des missions du consultant
                    </Text>
                </li>
            </ul>
            <Text>Relatives aux consultants :</Text>
            <ul>
                <li>
                    <Text>
                        <em>nom:XXX</em> : dans le nom du consultant
                    </Text>
                </li>
                <li>
                    <Text>
                        <em>prenom:XXX</em> : dans le prénom du consultant
                    </Text>
                </li>
                <li>
                    <Text>
                        <em>agence:XXX</em> : dans l'agence du consultant
                    </Text>
                </li>
                <li>
                    <Text>
                        <em>manager:XXX</em> : dans l'email du manager du consultant, par exemple :{' '}
                        <em>manager:vincent.bodet@nextdecision.fr</em> ou <em>manager:vincent.bodet</em>.
                    </Text>
                </li>
            </ul>
            <Text>Relatives aux missions et aux consultants :</Text>
            <ul>
                <li>
                    <Text>
                        <em>outil:XXX</em> : dans les outils du consultant et dans les outils des missions du consultant. Il est également
                        possible de rechercher sur le niveau de compétence du consultant (entre 0 à 5), par exemple{' '}
                        <em>outil:talend {'>'} 3</em> ou <em>outil:talend {'<'} 2</em>. La valeur peut donc est être entre 0 inclus et 4
                        inclus. À noter que seul les outils liés aux missions sont pris en compte pour le calcul du score.
                    </Text>
                </li>
            </ul>
            <br />
            <Text block>
                <b>Filtres conditionnels</b>
            </Text>
            <ul>
                <li>
                    <Text>
                        <em>ET</em> : Permet de faire une recherche afin de faire correspondre deux éléments. Exemple:{' '}
                        <em>outil:nodejs et prenom:john</em>
                    </Text>
                </li>
                <li>
                    <Text>
                        <em>OU</em> : Permet de faire une recherche afin de faire correspondre au moins un des éléments. Exemple:{' '}
                        <em>outil:nodejs ou prenom:john</em>
                    </Text>
                </li>
            </ul>
            <br />
            <Text block>
                <b>Recherche exacte</b>
            </Text>
            <Text>
                Pour faire une recherche de termes exacts, vous pouvez utiliser les guillemets, par exemple : <em>"power bi"</em>,{' '}
                <em>outil:"nodejs"</em> ou encore <em>prenom:"quentin"</em>
                .
                <br />
                Pour une recherche de plusieurs mots exacts, pensez à utilisateur les filtres conditionnels, par exemple :{' '}
                <em>"Azure API Management"</em> et <em>"Azure function"</em>.
            </Text>
            <br />
            <br />
            <Text>
                Si la chaîne contient plusieurs &nbsp;
                <em> et </em>
                &nbsp; et &nbsp;
                <em> ou </em>
                , alors les différentes sous-requêtes doivent être séparées par des parenthèses.
                <br />
                Ainsi les éléments suivants sont valides : <em>AAA et (BBB ou CCC)</em>, <em>(AAA et BBB) ou CCC</em>,{' '}
                <em>(AAA et BBB) ou (CCC et DDD)</em> ou <em>AAA ou (BBB et CCC et DDD)</em>; mais ceux-ci ne le sont pas :{' '}
                <em>AAA et BBB ou CCC</em> ou <em>AAA et (BBB ou CCC et DDD)</em>.
            </Text>
            {hasEndText && (
                <>
                    <br />
                    <br />
                    <br />
                    <Text>
                        Vous pourrez retrouver ces informations en cliquant sur le{' '}
                        <em>
                            <Icon iconName="Info" />
                        </em>{' '}
                        en haut de l'écran
                    </Text>
                </>
            )}
        </div>
    )
}
