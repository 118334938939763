import getFetch from 'requests/fetch'
import Exception from 'requests/models/exceptions/exception.model'
import { Synonym } from 'requests/models/objects'
import type { GetFetchParams } from 'requests/fetch'
import type { SynonymsApiResultResultType } from 'requests/types/results/synonym.type'

/**
 * Get synonyms
 */
export async function getAllSynonyms({
    cancelToken,
}: {
    /** CancelToken */
    cancelToken?: GetFetchParams['cancelToken']
}): Promise<Synonym> {
    try {
        const { data: res } = await getFetch<SynonymsApiResultResultType>({
            url: ['synonyms'],
            cancelToken,
        })

        return new Synonym(res.synonyms)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
        throw new Exception(error?.response?.data ?? error)
    }
}

/**
 * Upsert project
 */
export async function upsertSynonym({
    data,
    cancelToken,
}: {
    /** Data */
    data?: Synonym
    /** CancelToken */
    cancelToken?: GetFetchParams['cancelToken']
}): Promise<Synonym> {
    try {
        const { data: res } = await getFetch<SynonymsApiResultResultType>({
            url: ['synonyms'],
            cancelToken,
            method: 'PUT',
            data: { synonyms: data },
        })

        return new Synonym(res.synonyms)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
        throw new Exception(error?.response?.data ?? error)
    }
}
