import { useCallback, useMemo, useRef, useState } from 'react'
import { useHandleException } from 'hooks'
import { useGetAvailabilityCvs } from 'requests/hooks/cvs.hook'
import { useSelector } from 'store'
import { RESOLVED } from 'types/status'
import type { StatusType } from 'types/status'
import type { IDropdownOption } from '@fluentui/react'
import type { Cv } from 'requests/models/objects'

export type UseAvailabilityReturns = {
    /** Items */
    items?: Cv[]
    /** Status */
    status?: StatusType
    /** Results */
    results: string
    /** Headers */
    headers: {
        /** Mains */
        mains: string[]
        /** Subs */
        subs: string[]
    }
    /** TableContainerRef */
    tableContainerRef: React.RefObject<HTMLDivElement>
    /** SelectedPeriodKey */
    selectedPeriodKey: number
    /** OnSelectedPeriodChange */
    onSelectedPeriodChange: (_event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption | undefined) => void
    /** Period */
    period: string
}

/**
 * UseAvailabilityPage
 */
export default function useAvailability(): UseAvailabilityReturns {
    const { handleException } = useHandleException()
    const query = useSelector(state => state.common.query)
    const [selectedPeriodKey, setSelectedPeriodKey] = useState(4)

    const tableContainerRef = useRef<HTMLDivElement>(null)

    const { data: items, status } = useGetAvailabilityCvs(
        { ...query },
        {
            enabled: query.q !== null,
            onError(err) {
                handleException(err)
            },
        },
    )

    const results = useMemo(
        () => (status === RESOLVED ? `${new Intl.NumberFormat('fr-fr').format(items?.length || 0)} résultats` : ''),
        [items?.length, status],
    )

    const headers = useMemo(
        () => ({
            mains: new Array(Math.round((items?.[0]?.calendars.length ?? 0) / 5))
                .fill(null)
                .map((_, i) => {
                    const dt = new Date()
                    dt.setDate(dt.getDate() + i * 7)
                    const firstDayOfYear = new Date(dt.getFullYear(), 0, 1)
                    const days = Math.floor((dt.getTime() - firstDayOfYear.getTime()) / (24 * 60 * 60 * 1000))

                    return `Semaine ${Math.ceil(days / 7)}`
                })
                .slice(0, selectedPeriodKey),
            subs:
                items?.[0]?.calendars
                    .map(
                        calendar =>
                            [
                                ['Dim.', 'Lun.', 'Mar.', 'Mer.', 'Jeu.', 'Ven.', 'Sam.'][calendar.date?.getDay() ?? 0],
                                calendar.date?.toLocaleDateString('fr-fr', { month: '2-digit', day: '2-digit' }),
                            ].join(' ') ?? '',
                    )
                    .slice(0, selectedPeriodKey * 5) ?? [],
        }),
        [items, selectedPeriodKey],
    )

    const period = useMemo(() => {
        const calendars = items?.[0]?.calendars?.slice(0, selectedPeriodKey * 5) ?? []
        const [firstMonth, firstYear] = [
            calendars[0]?.date?.toLocaleDateString('fr-fr', { month: 'long' }).toFirstLetterUppercase(),
            calendars[0]?.date?.toLocaleDateString('fr-fr', { year: 'numeric' }),
        ]
        const [lastMonth, lastYear] = [
            calendars.at(-1)?.date?.toLocaleDateString('fr-fr', { month: 'long' }).toFirstLetterUppercase(),
            calendars.at(-1)?.date?.toLocaleDateString('fr-fr', { year: 'numeric' }),
        ]

        if (firstMonth === lastMonth) {
            return `${firstMonth} ${firstYear}`
        }

        if (firstYear === lastYear) {
            return `${firstMonth} - ${lastMonth} ${firstYear}`
        }

        return `${firstMonth} ${lastYear} - ${lastMonth} ${firstYear}`
    }, [items, selectedPeriodKey])

    const onSelectedPeriodChange = useCallback(
        (_event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption | undefined) =>
            setSelectedPeriodKey((option?.key as number) || 4),
        [],
    )

    return {
        items,
        status,
        results,
        headers,
        tableContainerRef,
        selectedPeriodKey,
        onSelectedPeriodChange,
        period,
    }
}
