import { DefaultButton } from '@fluentui/react'
import useDownloadButton from 'pages/index/components/tabs/skills/components/download-button/download-button.hook'
import { PENDING } from 'types/status'

/**
 * DownloadButton
 */
export default function DownloadButton() {
    const { onClickDownload, status } = useDownloadButton()

    return (
        <DefaultButton
            text="Télécharger"
            iconProps={{ iconName: 'DownloadDocument' }}
            onClick={onClickDownload}
            disabled={status === PENDING}
            styles={{
                root: {
                    marginLeft: 'auto',
                    display: 'block',
                },
            }}
        />
    )
}
