import { useMediaQuery } from 'react-responsive'
import { getVariable } from 'utils/styles'

export type UseMediaQueriesReturns = {
    /** IsTablet */
    isTablet: boolean
    /** IsMobile */
    isMobile: boolean
    /** IsTouchDevice */
    isTouchDevice: boolean
}

/**
 * UseMediaQueries
 */
export default function useMediaQueries(): UseMediaQueriesReturns {
    const isTablet = useMediaQuery({ query: `(max-width: calc(${getVariable('desktop')} - 1px))` })
    const isMobile = useMediaQuery({ query: `(max-width: calc(${getVariable('tablet')} - 1px))` })
    const isTouchDevice = useMediaQuery({ query: '(hover: none)' })

    return {
        isTablet,
        isMobile,
        isTouchDevice,
    }
}
