import type { CvResultType } from 'requests/types/results/cv.type'

/**
 * ScoreElement Object
 */
export default class ScoreElement {
    name: string

    score: number

    constructor({ name = '', score = 0 }: NonNullable<NonNullable<CvResultType['scores']>['0']['elements']>['0'] = {}) {
        this.name = name
        this.score = score
    }
}
