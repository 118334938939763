import { DirectionalHint, Text, TooltipDelay, TooltipHost } from '@fluentui/react'
import classNames from 'classnames'
import styles from 'pages/index/components/others/tooltip-name/tooltip-name.module.scss'
import DefaultPhoto from 'assets/img/default-photo.jpg'
import type { Cv } from 'requests/models/objects'

export type TooltipNameProps = {
    /** Item */
    item: Cv
    /** Children */
    children: React.ReactNode
    /** Children */
    tooltipChildren?: React.ReactNode
}

/**
 * TooltipName
 */
export default function TooltipName({ item, children, tooltipChildren = undefined }: TooltipNameProps): React.ReactElement {
    return (
        <TooltipHost
            content={
                <div className={classNames(styles['tooltip-name'])}>
                    <a
                        href={item.fileUrl}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img
                            src={item.photoUrl}
                            alt={item.photoUrl}
                            onError={({ currentTarget }) => {
                                // eslint-disable-next-line no-param-reassign
                                currentTarget.onerror = null
                                // eslint-disable-next-line no-param-reassign
                                currentTarget.src = DefaultPhoto
                            }}
                            loading="lazy"
                            decoding="async"
                        />
                    </a>
                    <Text block>{item.agency?.toLowerCase()?.toFirstLetterUppercase() ?? ''}</Text>
                    {item.phone && (
                        <Text block>
                            <a href={`tel:${item.phone}`}>{item.phone}</a>
                        </Text>
                    )}
                    {item.email && (
                        <Text block>
                            <a href={`mailto:${item.email}`}>{item.email}</a>
                        </Text>
                    )}
                    {tooltipChildren && <br />}
                    {tooltipChildren}
                </div>
            }
            directionalHint={DirectionalHint.topCenter}
            delay={TooltipDelay.zero}
            styles={{
                root: {
                    width: 'fit-content',
                    display: 'block',
                },
            }}
        >
            {children}
        </TooltipHost>
    )
}
