import variables from 'styles/exports/variables.module.scss'

/**
 * Get variable from SCSS
 * @param key Color key
 * @returns Variable
 */
export default function getVariable(
    key: 'shadow' | 'nav-left-width' | 'nav-left-closed-width' | 'bar-height' | 'gap' | 'tablet' | 'desktop' | 'widescreen' | 'fullhd',
): string {
    return variables[key]
}
