import { Text } from '@fluentui/react'
import styles from 'pages/index/components/tabs/validation/components/rules/rules.module.scss'

/**
 * Rules
 */
export default function Rules(): React.ReactElement {
    return (
        <Text className={styles.rules}>
            <ul>
                <li>
                    <b>Titre</b>
                    <ul>
                        <li>Le premier texte du document (en haut à gauche) est utilisé</li>
                    </ul>
                </li>
                <li>
                    <b>Secteurs</b>
                    <ul>
                        <li>Utilise les textes de le tableau la proche des mots "Secteurs d’activité"</li>
                        <li>Utilise toutes les images dont le texte alternatif respect ceci : "nextoo-secteur=Exemple"</li>
                    </ul>
                </li>
                <li>
                    <b>Formations</b>
                    <ul>
                        <li>Utilise les textes de le tableau la proche des mots "Formateur" ou "Formations"</li>
                    </ul>
                </li>
                <li>
                    <b>Certifications</b>
                    <ul>
                        <li>Utilise toutes les images dont le texte alternatif respect ceci : "nextoo-certification=Exemple"</li>
                    </ul>
                </li>
                <li>
                    <b>Outils et langages</b>
                    <ul>
                        <li>Utilise les textes de le tableau la proche des mots "Outils et langages"</li>
                        <li>Bien utiliser le tableau pour faire correspondre l'élément de gauche vers les éléments de droites</li>
                    </ul>
                </li>
                <li>
                    <b>Expériences</b>
                    <ul>
                        <li>
                            <b>Dates</b>
                            <ul>
                                <li>Correspond à la première ligne</li>
                                <li>
                                    Si deux deux dates
                                    <ul>
                                        <li>Sépération via : " - ", "-", " a " ou " à "</li>
                                        <li>Format : "Mois/Année à Mois/Année", "01/2000 - 02/2020" ou "Mois à Mois/Année"</li>
                                    </ul>
                                </li>
                                <li>
                                    Si une seule date (période d'un mois)
                                    <ul>
                                        <li>Format : "Mois/Année", "01/2000"</li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <b>Nombre de mois</b>
                            <ul>
                                <li>Calculé automatiquement</li>
                            </ul>
                        </li>
                        <li>
                            <b>Titre</b>
                            <ul>
                                <li>Correspond à la deuxième ligne</li>
                            </ul>
                        </li>
                        <li>
                            <b>Nom du client</b>
                            <ul>
                                <li>Correspond à la troisième ligne</li>
                                <li>Doit être en MAJUSCULE</li>
                                <li>S'il n'y a pas de client, indiquer "INTERNE" par exemple</li>
                            </ul>
                        </li>
                        <li>
                            <b>Nom du projet</b>
                            <ul>
                                <li>Doit commencer par "Projet :"</li>
                            </ul>
                        </li>
                        <li>
                            <b>Outils</b>
                            <ul>
                                <li>Doit commencer par : "Outils :", "Outil :", "Contexte :" ou "Environnement:"</li>
                                <li>Les éléments doivent-être séparés par des virgules</li>
                            </ul>
                        </li>
                        <li>
                            <b>Contenu</b>
                            <ul>
                                <li>Le reste qui ne rentre pas dans les autres catégories</li>
                            </ul>
                        </li>
                    </ul>
                </li>
            </ul>
        </Text>
    )
}
