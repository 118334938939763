import Base from 'requests/models/objects/_base.model'
import Experience from 'requests/models/objects/experience.model'
import Skill from 'requests/models/objects/skill.model'
import Validation from 'requests/models/objects/validation.model'
import Score from 'requests/models/objects/score.model'
import Calendar from 'requests/models/objects/calendar.model'
import type { CvResultType } from 'requests/types/results/cv.type'

/**
 * Cv Object
 */
export default class Cv extends Base {
    firstName: string

    lastName: string

    agency: string

    title: string

    sectors: string[]

    formations: string[]

    certificats: string[]

    skills: Skill[]

    experiences: Experience[]

    fileUpdatedAt: Date | undefined

    fileUrl: string

    validation: Validation | undefined

    hasDocumentFound: boolean

    ndArrivalDate: Date | undefined

    scores: Score[]

    email: string

    phone: string

    photoUrl: string

    calendars: Calendar[]

    manager: string

    constructor({
        firstName = '',
        lastName = '',
        agency = '',
        title = '',
        sectors = [],
        formations = [],
        certificats = [],
        skills = [],
        experiences = [],
        fileUpdatedAt = '',
        fileUrl = '',
        validation = {},
        hasDocumentFound = false,
        ndArrivalDate,
        scores = [],
        email = '',
        phone = '',
        calendars = [],
        manager = '',
        ...data
    }: CvResultType = {}) {
        super(data)
        this.firstName = firstName
        this.lastName = lastName
        this.agency = agency
        this.title = title
        this.sectors = sectors
        this.formations = formations
        this.certificats = certificats
        this.skills = skills?.map(x => new Skill(x)) ?? []
        this.experiences = experiences?.map(x => new Experience(x)) ?? []
        this.fileUpdatedAt = fileUpdatedAt ? new Date(fileUpdatedAt) : undefined
        this.fileUrl = fileUrl
        this.validation = validation ? new Validation(validation) : undefined
        this.hasDocumentFound = hasDocumentFound
        this.ndArrivalDate = ndArrivalDate ? new Date(ndArrivalDate) : undefined
        this.scores = scores?.map(x => new Score(x)) ?? []
        this.email = email
        this.phone = phone
        this.calendars = calendars?.map(x => new Calendar(x)) ?? []
        this.manager = manager

        this.photoUrl = `https://ftp.nextaccess.fr/1fa02b23-2260-40f0-9985-47eaacc429da/${this.firstName
            .split(/-|\s/g)
            .map(x => x[0])
            .join('')
            .toLowerCase()}${this.lastName.toLowerCase().replace(/-|'/g, '')}-1.jpg`.replace(/\s/g, '')
    }
}
