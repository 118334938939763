import { useMemo } from 'react'
import { useHandleException } from 'hooks'
import { useGetErrorCvs } from 'requests/hooks/cvs.hook'
import { RESOLVED } from 'types/status'
import type { Cv } from 'requests/models/objects'
import type { StatusType } from 'types/status'

const intl = new Intl.NumberFormat('fr-fr')

export type UseErrorsReturns = {
    /** Items */
    items: Cv[]
    /** Status */
    status: StatusType
    /** Results */
    results?: string
}

/**
 * UseErrorsPage
 */
export default function useErrors(): UseErrorsReturns {
    const { handleException } = useHandleException()

    const { data: items, status } = useGetErrorCvs(
        { hasDocumentFound: true },
        {
            onError(err) {
                handleException(err)
            },
        },
    )

    const results = useMemo(
        () =>
            status === RESOLVED
                ? [
                      `${intl.format(items?.reduce((prev, curr) => prev + (curr.validation?.errors.length ?? 0), 0))} erreurs`,
                      `${intl.format(items?.reduce((prev, curr) => prev + (curr.validation?.warnings.length ?? 0), 0))} avertissements`,
                  ].join(' et ')
                : '',
        [items, status],
    )

    return {
        items: items || [],
        status,
        results,
    }
}
