import axios from 'axios'
import type { AxiosError, AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios'

export const fetchInstance = axios.create({
    baseURL: `${origin}/api`,
})

export type GetFetchReturnType<T> = Promise<AxiosResponse<T>>

export type GetFetchParams = {
    /** Url */
    url?: (string | number | undefined)[]
    /** Method */
    method?: AxiosRequestConfig['method']
    /** Data */
    data?: AxiosRequestConfig['data']
    /** Params */
    params?: AxiosRequestConfig['params']
    /** Headers */
    headers?: AxiosRequestConfig['headers']
    /** ResponseType */
    responseType?: AxiosRequestConfig['responseType']
    /** CancelToken */
    cancelToken?: CancelTokenSource
    /** AuthToken */
    authToken?: string
}

/**
 * Init a new request
 * @param param Param
 */
export default function getFetch<T>({
    url = [],
    method = 'GET',
    data = {},
    params = {},
    responseType = 'json',
    headers = {},
    cancelToken = undefined,
}: GetFetchParams): GetFetchReturnType<T> {
    return fetchInstance.request({
        url: `${url.length ? `/${url.filter(x => x).join('/')}` : ''}`,
        method,
        cancelToken: cancelToken?.token,
        data,
        params,
        headers,
        responseType,
    })
}

/**
 * GetCancelToken
 * @returns Cancel Token
 */
export function getCancelToken(): CancelTokenSource {
    return axios.CancelToken.source()
}

/**
 * Is the error thrown a cancel error
 * @param err Error
 * @returns Is a cancel error?
 */
export function isCancelError(err: AxiosError) {
    return axios.isCancel(err)
}
