import { PureComponent } from 'react'
import { AnimationStyles, SharedColors, Icon, TextField, Dropdown as MsDropdown, Label, getTheme } from '@fluentui/react'
import classNames from 'classnames'
import styles from 'components/inputs/dropdown/dropdown.module.scss'
import type { IDropdownOption, IDropdownProps, IIconProps, ITextFieldProps } from '@fluentui/react'

export const IS_MULTI_SELECT_CLASSNAME = 'is-multi-select'

/**
 * Dropdown
 */
export default class Dropdown extends PureComponent<IDropdownProps & Omit<ITextFieldProps, 'onChange'>> {
    /**
     * Get color of Icon
     * @param iconName iconName
     * @returns Color of the icon
     */
    // eslint-disable-next-line class-methods-use-this
    getIconColor(iconName: IIconProps['iconName']): string {
        switch (iconName) {
            case 'Accept':
                return SharedColors.green10
            case 'Cancel':
                return SharedColors.red10
            default:
                return ''
        }
    }

    /**
     * Render
     */
    render() {
        const {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            readOnly,
            selectedKey,
            options,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            required,
            label,
            className,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            multiSelect,
        } = this.props

        if (readOnly) {
            const propsClean = { ...this.props }
            delete propsClean.borderless
            delete propsClean.readOnly
            delete propsClean.label

            /** Icon to display */
            const icon: IIconProps['iconName'] = selectedKey
                ? options?.find(x => x.key === propsClean?.selectedKey)?.data?.icon
                : options?.filter(x => propsClean?.selectedKeys?.includes(x.key as never))?.[0]?.data?.icon

            return (
                <div className={classNames(styles.dropdown, styles['is-readonly'])}>
                    {icon && <Label required={required}>{label}</Label>}
                    <div>
                        {icon && (
                            <Icon
                                // Style={{ marginRight: '1px', height: 'auto' }}
                                iconName={icon}
                                styles={{ root: { color: this.getIconColor(icon) } }}
                            />
                        )}
                        <TextField
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...propsClean}
                            label={!icon ? label : undefined}
                            required={!icon ? required : undefined}
                            borderless
                            readOnly
                            value={
                                (propsClean.defaultValue ??
                                    (selectedKey
                                        ? options?.find(x => x.key === propsClean?.selectedKey)?.text
                                        : options
                                              ?.filter(x => propsClean?.selectedKeys?.includes(x.key as never))
                                              .map(x => x.text)
                                              .join(', '))) ||
                                ''
                            }
                            onChange={() => null}
                        />
                    </div>
                </div>
            )
        }
        const propsClean = { ...this.props }
        delete propsClean.onRenderOption
        delete propsClean.onRenderTitle

        return (
            <div className={classNames(styles.dropdown, styles['is-not-readonly'])}>
                <MsDropdown
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...this.props}
                    className={classNames(className, { [IS_MULTI_SELECT_CLASSNAME]: multiSelect })}
                    onChange={(ev, option, index) => {
                        const opt = option?.key !== propsClean.selectedKey ? option : { key: null, text: null }
                        if (propsClean.onChange) {
                            propsClean.onChange(ev, opt as IDropdownOption, index)
                        }
                    }}
                    // eslint-disable-next-line react/no-unstable-nested-components
                    onRenderOption={option => (
                        <>
                            {option?.data?.icon && (
                                <Icon
                                    style={{ marginRight: '8px', verticalAlign: 'bottom' }}
                                    iconName={option.data?.icon}
                                    styles={{ root: { color: this.getIconColor(option.data?.icon) } }}
                                />
                            )}
                            <span
                                style={{
                                    color: propsClean.selectedKey === option?.key ? getTheme().palette.themeSecondary : undefined,
                                    fontWeight: propsClean.selectedKey === option?.key ? 'bold' : undefined,
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                {option?.text ?? ''}
                            </span>
                        </>
                    )}
                    // eslint-disable-next-line react/no-unstable-nested-components
                    onRenderTitle={opts => {
                        const option = opts?.[0]

                        return (
                            <>
                                {option?.data?.icon && (
                                    <Icon
                                        style={{ marginRight: '8px', verticalAlign: 'bottom' }}
                                        iconName={option?.data?.icon}
                                        styles={{ root: { color: this.getIconColor(option?.data?.icon) } }}
                                    />
                                )}
                                <span>{option?.text}</span>
                            </>
                        )
                    }}
                    styles={{
                        ...propsClean.styles,
                        errorMessage: {
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            ...(propsClean.styles?.errorMessage ?? {}),
                            ...AnimationStyles.slideDownIn20,
                        },
                    }}
                />
                {required && (
                    <TextField
                        value={selectedKey?.toString() || ''}
                        onChange={() => null}
                        styles={{
                            wrapper: {
                                opacity: 0,
                            },
                            root: {
                                position: 'absolute',
                                bottom: 0,
                                pointerEvents: 'none',
                            },
                        }}
                        tabIndex={-1}
                        required
                    />
                )}
            </div>
        )
    }
}
