import { configureStore } from '@reduxjs/toolkit'
import { useDispatch as useDispatchRedux, useSelector as useSelectorRedux } from 'react-redux'
import { commonMiddleware, commonReducer } from 'store/slices/common.slice'
// eslint-disable-next-line import/no-cycle
import { userMiddleware, userReducer } from 'store/slices/user.slice'
import type { TypedUseSelectorHook } from 'react-redux'

/**
 * @see https://www.valentinog.com/blog/redux/#modern-redux-with-redux-toolkit
 */
const store = configureStore({
    reducer: {
        user: userReducer,
        common: commonReducer,
    },
    middleware: getDefaultMiddleware => [...getDefaultMiddleware({ serializableCheck: false }), ...userMiddleware, ...commonMiddleware],
})

export type StoreStateType = ReturnType<typeof store.getState>

export type StoreDispatchType = typeof store.dispatch

export const useDispatch = useDispatchRedux<StoreDispatchType>

export const useSelector: TypedUseSelectorHook<StoreStateType> = useSelectorRedux

export default store
