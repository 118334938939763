import classNames from 'classnames'
import { Helmet as Head } from 'react-helmet'
import { List, Text } from '@fluentui/react'
import styles from 'pages/index/components/tabs/all/all.module.scss'
import useAll from 'pages/index/components/tabs/all/all.hook'
import { IDLE, PENDING } from 'types/status'
import { Loader } from 'components/elements'
import Card from 'pages/index/components/tabs/all/components/card/card.component'
import HelpText from 'pages/index/components/others/help-text/help-text.component'

/**
 * All page
 */
export default function All(): React.ReactElement {
    const { items, status, results } = useAll()

    return (
        <>
            <Head>
                <title>Tous - Nextoo</title>
            </Head>

            <main className={classNames(styles.all)}>
                <Text>{results}</Text>
                {status === IDLE && (
                    <Text>
                        Veuillez effectuer une recherche
                        <br />
                        <br />
                        <br />
                        <HelpText hasEndText />
                    </Text>
                )}
                {status === PENDING && <Loader />}
                <br />
                <br />
                <List
                    className={classNames(styles['all-container'])}
                    items={items}
                    renderedWindowsAhead={6}
                    renderedWindowsBehind={6}
                    // eslint-disable-next-line react/no-unstable-nested-components
                    onRenderCell={(item, index) => (
                        <Card
                            key={`${item?.fileUrl}_${index}`}
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            item={item!}
                        />
                    )}
                />
            </main>
        </>
    )
}
