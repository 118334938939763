import classNames from 'classnames'
import { Helmet as Head } from 'react-helmet'
import { List, Text } from '@fluentui/react'
import React from 'react'
import styles from 'pages/index/components/tabs/not-found/not-found.module.scss'
import useNotFound from 'pages/index/components/tabs/not-found/not-found.hook'
import { PENDING } from 'types/status'
import { Loader } from 'components/elements'

/**
 * NotFound page
 */
export default function NotFound(): React.ReactElement {
    const { items, status, results } = useNotFound()

    return (
        <>
            <Head>
                <title>CV non trouvés - Nextoo</title>
            </Head>

            <main className={classNames(styles['not-found'])}>
                <Text>{results}</Text>
                {status === PENDING && <Loader />}
                <br />
                <br />
                <List
                    items={items}
                    renderedWindowsAhead={6}
                    renderedWindowsBehind={6}
                    onShouldVirtualize={() => false}
                    // eslint-disable-next-line react/no-unstable-nested-components
                    onRenderCell={(item, index) => (
                        <React.Fragment key={`item${index}`}>
                            <Text
                                as="h2"
                                variant="large"
                                block
                            >
                                {item?.lastName} {item?.firstName} ({item?.agency})
                            </Text>
                            <Text
                                as="h3"
                                variant="smallPlus"
                                className={classNames(styles.subtitle)}
                                block
                            >
                                Date d'arrivée : {item?.ndArrivalDate?.toLocaleDateString('fr-fr')}
                            </Text>
                            <br />
                        </React.Fragment>
                    )}
                />
            </main>
        </>
    )
}
