import { Dialog, DialogType, PrimaryButton } from '@fluentui/react'
import classNames from 'classnames'
import styles from 'pages/index/components/tabs/skills/components/skills-row/skills-row.module.scss'
import useSkillsRow from 'pages/index/components/tabs/skills/components/skills-row/skills-row.hook'
import { Portal } from 'components/containers'
import MindMap from 'pages/index/components/others/mindmap/mindmap.component'
import TooltipName from 'pages/index/components/others/tooltip-name/tooltip-name.component'
import type { Cv } from 'requests/models/objects'

export type SkillsRowProps = {
    /** Item */
    item: Cv
    /** CategoriesOpen */
    categoriesOpen: boolean[]
}

/**
 * SkillsRow
 */
export default function SkillsRow({ item, categoriesOpen }: SkillsRowProps) {
    const { isModalVisible, setIsModalVisible, portalId, isAllZeros } = useSkillsRow({ item, categoriesOpen })

    return (
        <>
            {!isAllZeros && (
                <tr className={classNames(styles['skills-row'])}>
                    <td>
                        <TooltipName
                            item={item}
                            tooltipChildren={
                                <PrimaryButton
                                    text="Voir la mindmap"
                                    iconProps={{ iconName: 'VisioDiagram' }}
                                    onClick={() => setIsModalVisible(true)}
                                />
                            }
                        >
                            {item.lastName} {item.firstName}
                        </TooltipName>
                    </td>
                    {item?.scores
                        .filter(score => score.elements.length > 0)
                        .map((score, i) => {
                            if (!categoriesOpen[i]) {
                                return null
                            }

                            return score.elements.map(element => (
                                <td
                                    key={`td_score_${item.lastName}_${item.firstName}_${item.agency}_${element.name}`}
                                    className={classNames(
                                        styles['has-score'],
                                        { [styles['is-one']]: element.score === 1 },
                                        { [styles['is-two']]: element.score === 2 },
                                        { [styles['is-three']]: element.score === 3 },
                                        { [styles['is-four']]: element.score === 4 },
                                        { [styles['is-five']]: element.score === 5 },
                                        { [styles['is-close']]: !categoriesOpen[i] },
                                    )}
                                >
                                    <div
                                        title={
                                            {
                                                1: `Notion ${element.name}`,
                                                2: `Connaissance ${element.name}`,
                                                3: `Experimenté ${element.name}`,
                                                4: `Confirmé ${element.name}`,
                                                5: `Expert ${element.name}`,
                                            }[element.score]
                                        }
                                    >
                                        {element.score > 0 ? new Array(element.score).fill('★') : '-'}
                                    </div>
                                </td>
                            ))
                        })}
                </tr>
            )}

            {isModalVisible && (
                <Portal className={`portal-${portalId}`}>
                    <Dialog
                        hidden={!isModalVisible}
                        onDismiss={() => setIsModalVisible(false)}
                        dialogContentProps={{
                            type: DialogType.close,
                            title: 'Mindmap',
                            closeButtonAriaLabel: 'Close',
                            showCloseButton: true,
                        }}
                        modalProps={{
                            isBlocking: false,
                        }}
                        maxWidth="1200px"
                    >
                        <MindMap item={item} />
                    </Dialog>
                </Portal>
            )}
        </>
    )
}
