const HandleBlob = {
    /**
     * Download a blob
     * @param file File to download
     * @param fileName Name of your file
     */
    download(file: Blob, fileName: string) {
        const a = document.createElement('a')
        a.href = window.URL.createObjectURL(file)
        a.download = fileName
        if (document.querySelector('.ms-Dialog-actionsRight')) {
            // Workaround to dl from a modal
            document.querySelector('.ms-Dialog-actionsRight')?.appendChild(a)
        } else {
            document.body.appendChild(a)
        }

        a.click()
        a.remove()
    },
    /**
     * Open a blob
     * @param file File to open
     */
    open(file: Blob) {
        window.open(window.URL.createObjectURL(file), '_blank')
    },
}

export default HandleBlob
