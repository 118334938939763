import { useLocation } from 'react-router-dom'
import { setMessageBar } from 'store/slices/common.slice'
import { useDispatch, useSelector } from 'store'
import type { Location } from 'react-router-dom'
import type { PayloadMessageBarType } from 'store/slices/common.slice'
import type { StoreStateType } from 'store'

export type UseLayoutPublicParamReturns = {
    /** MessageBar */
    messageBar: StoreStateType['common']['messageBar']
    /** IsAuthenticated */
    isAuthenticated: StoreStateType['user']['isAuthenticated']
    /** SetMessageBar */
    setMessageBar: (params: PayloadMessageBarType) => void
    /** Location */
    location: Location
}

/**
 * UseLayoutPage
 */
export default function useLayoutPublic(): UseLayoutPublicParamReturns {
    const dispatch = useDispatch()
    const location = useLocation()
    const messageBar = useSelector(state => state.common.messageBar)
    const isAuthenticated = useSelector(state => state.user.isAuthenticated)

    return {
        messageBar,
        isAuthenticated,
        setMessageBar: params => dispatch(setMessageBar(params)),
        location,
    }
}
