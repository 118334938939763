import { useRoutes } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'
import { MessageBarType } from '@fluentui/react'
import LayoutPrivate from 'pages/_layout-private/layout-private.component'
import Index from 'pages/index/index.component'
import NotFound from 'pages/404/404.component'
import { useHandleException, usePrevious } from 'hooks'
import { useDispatch, useSelector } from 'store'
import { setMessageBar } from 'store/slices/common.slice'
import { getMe, patchUsersMeRefreshToken } from 'requests/handlers/users.handler'
import { STORAGE_TOKEN_KEY, STORAGE_TOKEN_REFRESH_KEY, STORAGE_USER_KEY } from 'types/others'
import { fetchInstance, isCancelError } from 'requests/fetch'
import { init, signOut } from 'store/slices/user.slice'
import Login from 'pages/login/login.component'
import LayoutPublic from 'pages/_layout-public/layout-public.component'
import type { AxiosError } from 'axios'

export type UseAppReturns = {
    /** Routes */
    routes: React.ReactElement | null
    /** Is app loading */
    isAppLoaded: boolean
}

/**
 * UseApp
 */
export default function useApp(): UseAppReturns {
    const { handleException } = useHandleException()
    const dispatch = useDispatch()
    const isAuthenticated = useSelector(state => state.user.isAuthenticated)

    const [isAppLoaded, setIsAppLoaded] = useState(false)

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const prevIsAuthenticated = usePrevious(isAuthenticated)

    const refresh = useCallback(async () => {
        dispatch(setMessageBar({ isDisplayed: false }))
        setIsAppLoaded(false)
        try {
            const me = await getMe()
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            dispatch(init({ me: me as any }))
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error)
            setMessageBar?.({
                isDisplayed: true,
                type: MessageBarType.error,
                message: error as string,
            })
            dispatch(signOut({ isWithLogoutApi: false }))
        } finally {
            setIsAppLoaded(true)
        }
    }, [dispatch])

    // Force refresh by removing data from cache
    const forceRefresh = useCallback(() => {
        localStorage.removeItem(STORAGE_USER_KEY)
        refresh()
    }, [refresh])

    // Add axios interceptor to handle refresh token
    useEffect(() => {
        fetchInstance.interceptors.request.use(
            config => {
                const token = localStorage.getItem(STORAGE_TOKEN_KEY)
                if (token) {
                    if (config.headers) {
                        // eslint-disable-next-line no-param-reassign
                        config.headers.Authorization = `Bearer ${token}`
                    }
                }

                return config
            },
            error => Promise.reject(error),
        )

        fetchInstance.interceptors.response.use(
            res => res,
            // eslint-disable-next-line jsdoc/require-jsdoc
            async (err: AxiosError & { config: { retry: boolean } }) => {
                const originalConfig = err.config

                if (
                    !isCancelError(err) &&
                    !originalConfig.url?.includes('users/refresh') &&
                    err.response?.status === 401 &&
                    !originalConfig.retry
                ) {
                    originalConfig.retry = true

                    try {
                        const { accessToken } = await patchUsersMeRefreshToken({
                            data: {
                                refreshToken: localStorage.getItem(STORAGE_TOKEN_REFRESH_KEY) || '',
                            },
                        })

                        if (accessToken) {
                            localStorage.setItem(STORAGE_TOKEN_KEY, accessToken)
                        }

                        return await fetchInstance.request(originalConfig)
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    } catch (error: any) {
                        handleException(error)
                        return Promise.reject(error)
                    }
                }

                return Promise.reject(err)
            },
        )
    }, [handleException])

    // Listen to isAuthenticated to get param and user data
    useEffect(() => {
        if (isAuthenticated && isAuthenticated !== prevIsAuthenticated) {
            refresh()
        }
    }, [isAuthenticated, prevIsAuthenticated, refresh])

    const routes = useRoutes([
        {
            element: <LayoutPublic />,
            children: [{ path: '/login', element: <Login /> }],
        },
        {
            element: (
                <LayoutPrivate
                    isAppLoaded={isAppLoaded}
                    onRefresh={forceRefresh}
                />
            ),
            children: [
                { path: '/', element: <Index /> },
                { path: '*', element: <NotFound /> },
            ],
        },
    ])

    return {
        routes,
        isAppLoaded,
    }
}
