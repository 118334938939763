import classNames from 'classnames'
import { Helmet as Head } from 'react-helmet'
import { PrimaryButton, Spinner, SpinnerSize, TextField } from '@fluentui/react'
import useLogin from 'pages/login/login.hook'
import styles from 'pages/login/login.module.scss'
import { PENDING } from 'types/status'

/**
 * Login Page
 * @param props Props
 */
export default function Login(): React.ReactElement {
    const { login, user, exception, status, handleChange } = useLogin()

    return (
        <>
            <Head>
                <title>Se connecter - Nextoo</title>
            </Head>

            <main className={classNames(styles.login)}>
                <form onSubmit={login}>
                    <TextField
                        label="Nom d'utilisateur Windows / AD / VPN"
                        placeholder="Nom d'utilisateur"
                        name="username"
                        value={user.username}
                        readOnly={status === PENDING}
                        autoComplete="username"
                        errorMessage={exception?.invalidFields?.username}
                        onChange={handleChange}
                        required
                    />
                    <br />
                    <TextField
                        label="Mot de passe Windows / AD / VPN"
                        placeholder="Mot de passe"
                        type="password"
                        name="password"
                        value={user.password}
                        readOnly={status === PENDING}
                        autoComplete="current-password"
                        errorMessage={exception?.invalidFields?.password}
                        onChange={handleChange}
                        required
                        canRevealPassword
                    />
                    <br />
                    <div className={classNames(styles['login-button-container'])}>
                        <PrimaryButton
                            iconProps={{ iconName: 'FollowUser' }}
                            text="Se connecter"
                            type="submit"
                            disabled={status === PENDING}
                        >
                            {status === PENDING && <Spinner size={SpinnerSize.small} />}
                        </PrimaryButton>
                    </div>
                </form>
            </main>
        </>
    )
}
