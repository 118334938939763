import classNames from 'classnames'
import { Helmet as Head } from 'react-helmet'
import { Text } from '@fluentui/react'
import useValidation from 'pages/index/components/tabs/validation/validation.hook'
import styles from 'pages/index/components/tabs/validation/validation.module.scss'
import { PENDING, REJECTED, RESOLVED } from 'types/status'
import { Loader } from 'components/elements'
import Rules from 'pages/index/components/tabs/validation/components/rules/rules.component'
import Tips from 'pages/index/components/tabs/validation/components/tips/tips.component'
import MindMap from 'pages/index/components/others/mindmap/mindmap.component'
import { Cv } from 'requests/models/objects'
import Scores from 'pages/index/components/tabs/validation/components/scores/scores.component'

/**
 * Validation page
 */
export default function Validation(): React.ReactElement {
    const { onUpload, onClick, item, status } = useValidation()

    return (
        <>
            <Head>
                <title>Validation - Nextoo</title>
            </Head>

            <main className={classNames(styles.validation)}>
                <input
                    type="file"
                    onChange={onUpload}
                    onClick={onClick}
                    accept=".docx"
                />
                <br />
                <br />
                <hr />
                <br />
                <div className={classNames(styles['validation-container'])}>
                    <div className={classNames(styles['validation-container-left'])}>
                        <Text
                            as="h2"
                            variant="large"
                            block
                        >
                            Rappel des règles d'indexation :
                        </Text>
                        <Rules />
                        <br />
                        <Text
                            as="h2"
                            variant="large"
                            block
                        >
                            Astuces en cas de problèmes :
                        </Text>
                        <Tips />
                        <br />
                        <Text
                            as="h2"
                            variant="large"
                            block
                        >
                            Règles pour les niveaux d'expérience :
                        </Text>
                        <Scores />
                    </div>
                    <div className={classNames(styles['validation-container-right'])}>
                        {status === PENDING && <Loader />}
                        {[RESOLVED, REJECTED].includes(status) && !item && <Text>Aucune donnée n'a été trouvée</Text>}
                        {[RESOLVED, REJECTED].includes(status) && item && (
                            <>
                                <Text
                                    as="h2"
                                    variant="large"
                                    block
                                >
                                    Erreurs :
                                </Text>
                                {!!item?.validation?.errors?.length && (
                                    <ul>
                                        {item?.validation?.errors.map((error, i) => (
                                            // eslint-disable-next-line react/no-array-index-key
                                            <li key={`error_${i}`}>
                                                <Text>{error}</Text>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                                {!item?.validation?.errors?.length && (
                                    <Text>
                                        Aucune erreur n'a été trouvée
                                        <br />
                                    </Text>
                                )}
                                <br />
                                <Text
                                    as="h2"
                                    variant="large"
                                    block
                                >
                                    Avertissements :
                                </Text>
                                {!!item?.validation?.warnings?.length && (
                                    <ul>
                                        {item?.validation?.warnings.map((warning, i) => (
                                            // eslint-disable-next-line react/no-array-index-key
                                            <li key={`warning${i}`}>
                                                <Text>{warning}</Text>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                                {!item?.validation?.warnings?.length && (
                                    <Text>
                                        Aucun avertissement n'a été trouvé
                                        <br />
                                    </Text>
                                )}
                                <br />
                                <Text
                                    as="h2"
                                    variant="large"
                                    block
                                >
                                    Mindmap générée :
                                </Text>
                                <MindMap
                                    item={item || new Cv()}
                                    style={{ width: '100%' }}
                                />
                                <br />
                                <br />
                                <Text
                                    as="h2"
                                    variant="large"
                                    block
                                >
                                    Données indéxées :
                                </Text>
                                <Text
                                    as="h3"
                                    block
                                >
                                    <b>Titre</b> :{' '}
                                    {item?.title || (
                                        <i>
                                            <em>Aucune donnée</em>
                                        </i>
                                    )}
                                </Text>
                                <Text
                                    as="h3"
                                    block
                                >
                                    <b>Secteurs</b> :{' '}
                                    {new Intl.ListFormat('fr', { style: 'long', type: 'conjunction' }).format(item?.sectors ?? []) || (
                                        <i>
                                            <em>Aucune donnée</em>
                                        </i>
                                    )}
                                </Text>
                                <Text
                                    as="h3"
                                    block
                                >
                                    <b>Formations</b> :{' '}
                                    {new Intl.ListFormat('fr', { style: 'long', type: 'conjunction' }).format(item?.formations ?? []) || (
                                        <i>
                                            <em>Aucune donnée</em>
                                        </i>
                                    )}
                                </Text>
                                <Text
                                    as="h3"
                                    block
                                >
                                    <b>Certifications</b> :{' '}
                                    {new Intl.ListFormat('fr', { style: 'long', type: 'conjunction' }).format(item?.certificats ?? []) || (
                                        <i>
                                            <em>Aucune donnée</em>
                                        </i>
                                    )}
                                </Text>
                                <Text
                                    as="h3"
                                    block
                                >
                                    <b>Outils et langages</b> :{' '}
                                    {!!item?.skills?.length && (
                                        <ul>
                                            {item?.skills.map((skill, i) => (
                                                // eslint-disable-next-line react/no-array-index-key
                                                <li key={`skill${i}`}>
                                                    <Text>
                                                        <u>
                                                            {skill.name || (
                                                                <i>
                                                                    <em>Aucune donnée</em>
                                                                </i>
                                                            )}
                                                        </u>{' '}
                                                        :{' '}
                                                        {new Intl.ListFormat('fr', { style: 'long', type: 'conjunction' }).format(
                                                            skill.elements ?? [],
                                                        ) || (
                                                            <i>
                                                                <em>Aucune donnée</em>
                                                            </i>
                                                        )}
                                                    </Text>
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                    {!item?.skills?.length && (
                                        <i>
                                            <em>Aucune donnée</em>
                                        </i>
                                    )}
                                </Text>
                                <Text
                                    as="h3"
                                    block
                                >
                                    <b>Experiences</b> :{' '}
                                    {!!item?.experiences?.length && (
                                        <ul>
                                            {item?.experiences.map((experience, i) => (
                                                // eslint-disable-next-line react/no-array-index-key
                                                <li key={`experience${i}`}>
                                                    <Text block>
                                                        <u>Date de début</u> :{' '}
                                                        {experience.startDate
                                                            ?.toLocaleDateString('fr-fr', { month: 'long', year: 'numeric' })
                                                            ?.toFirstLetterUppercase() || (
                                                            <i>
                                                                <em>Aucune donnée</em>
                                                            </i>
                                                        )}
                                                        <br />
                                                        <u>Date de fin</u> :{' '}
                                                        {experience.endDate
                                                            ?.toLocaleDateString('fr-fr', { month: 'long', year: 'numeric' })
                                                            ?.toFirstLetterUppercase() || (
                                                            <i>
                                                                <em>Aucune donnée</em>
                                                            </i>
                                                        )}
                                                        <br />
                                                        <u>Nombre de mois</u> :{' '}
                                                        {experience.months || (
                                                            <i>
                                                                <em>Aucune donnée</em>
                                                            </i>
                                                        )}
                                                        <br />
                                                        <u>Titre</u> :{' '}
                                                        {experience.title || (
                                                            <i>
                                                                <em>Aucune donnée</em>
                                                            </i>
                                                        )}
                                                        <br />
                                                        <u>Nom du client</u> :{' '}
                                                        {experience.clientName || (
                                                            <i>
                                                                <em>Aucune donnée</em>
                                                            </i>
                                                        )}
                                                        <br />
                                                        <u>Nom du projet</u> :{' '}
                                                        {experience.projectName || (
                                                            <i>
                                                                <em>Aucune donnée</em>
                                                            </i>
                                                        )}
                                                        <br />
                                                        <u>Contenu</u> :{' '}
                                                        {experience.content || (
                                                            <i>
                                                                <em>Aucune donnée</em>
                                                            </i>
                                                        )}
                                                        <br />
                                                        <u>Outils</u> :{' '}
                                                        {new Intl.ListFormat('fr', { style: 'long', type: 'conjunction' }).format(
                                                            experience.tools ?? [],
                                                        ) || (
                                                            <i>
                                                                <em>Aucune donnée</em>
                                                            </i>
                                                        )}
                                                    </Text>
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                    {!item?.experiences?.length && (
                                        <i>
                                            <em>Aucune donnée</em>
                                        </i>
                                    )}
                                </Text>
                            </>
                        )}
                    </div>
                </div>
            </main>
        </>
    )
}
