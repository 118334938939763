import { useMemo } from 'react'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import type { Location, NavigateFunction } from 'react-router-dom'

export type MatchParams = {
    /** Projectid */
    projectid?: string
}

export type UseRouterReturns = {
    /** Location */
    location: Location
    /** Navigate */
    navigate: NavigateFunction
    /** Match */
    match: MatchParams
}

/**
 * Use router
 */
export default function useRouter(): UseRouterReturns {
    const location = useLocation()
    const navigate = useNavigate()
    const match = useParams()

    return useMemo(
        () => ({
            location,
            navigate,
            match,
        }),
        [location, navigate, match],
    )
}
