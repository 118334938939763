import classNames from 'classnames'
import { Helmet as Head } from 'react-helmet'
import { GoogleMap } from '@react-google-maps/api'
import { Text } from '@fluentui/react'
import React from 'react'
import Circle from 'pages/index/components/tabs/map/components/circle/circle.component'
import { IDLE, PENDING, RESOLVED } from 'types/status'
import { Loader } from 'components/elements'
import useMap from 'pages/index/components/tabs/map/map.hook'
import styles from 'pages/index/components/tabs/map/map.module.scss'
import Agency from 'pages/index/components/tabs/map/components/agency/agency.component'
import HelpText from 'pages/index/components/others/help-text/help-text.component'
import type { CircleProps } from 'pages/index/components/tabs/map/components/circle/circle.component'

const MAP_OPTIONS: google.maps.MapOptions = {
    mapTypeControl: false,
    clickableIcons: false,
    zoomControl: false,
    fullscreenControl: false,
    streetViewControl: false,
    keyboardShortcuts: false,
    disableDoubleClickZoom: true,
    gestureHandling: 'none',
    styles: [
        { featureType: 'road', stylers: [{ visibility: 'off' }] },
        { featureType: 'administrative', stylers: [{ visibility: 'off' }] },
        { featureType: 'poi', stylers: [{ visibility: 'off' }] },
        { featureType: 'administrative', stylers: [{ visibility: 'off' }] },
        { elementType: 'labels', stylers: [{ visibility: 'off' }] },
    ],
}

const MAP_CONTAINER_STYLE: React.CSSProperties = {
    minHeight: '650px',
    height: '70vh',
}

const MAP_CENTER: google.maps.LatLngLiteral = {
    lat: 46.71109,
    lng: 1.7191036,
}

/**
 * Map page
 */
export default function Map(): React.ReactElement {
    const { isMapLoaded, items, status, results, isTablet } = useMap()

    return (
        <>
            <Head>
                <title>Carte - Nextoo</title>
            </Head>

            <main className={classNames(styles.map)}>
                <Text>{results}</Text>
                {status === IDLE && (
                    <Text>
                        Veuillez effectuer une recherche
                        <br />
                        <br />
                        <br />
                        <HelpText hasEndText />
                    </Text>
                )}
                {status === PENDING && <Loader />}
                <br />
                <br />
                {status === RESOLVED && (
                    <>
                        {' '}
                        <hr />
                        <div className={classNames(styles['map-container'])}>
                            <div className={classNames(styles['map-container-left'])}>
                                <Text
                                    variant="large"
                                    block
                                >
                                    Carte
                                </Text>
                                {isMapLoaded && (
                                    <GoogleMap
                                        mapContainerStyle={MAP_CONTAINER_STYLE}
                                        center={MAP_CENTER}
                                        zoom={isTablet ? 5 : 6}
                                        options={MAP_OPTIONS}
                                    >
                                        {Object.keys(items).map(agency => (
                                            <Circle
                                                key={`circle-${agency}`}
                                                agency={agency as CircleProps['agency']}
                                                total={items[agency].length}
                                            />
                                        ))}
                                    </GoogleMap>
                                )}
                            </div>
                            <div className={classNames(styles['map-container-right'])}>
                                <Text
                                    variant="large"
                                    block
                                >
                                    Consultants par agence
                                </Text>
                                {Object.keys(items).map(agency => (
                                    <Agency
                                        key={`data-${agency}`}
                                        agency={agency}
                                        items={items[agency] ?? []}
                                    />
                                ))}
                            </div>
                        </div>
                    </>
                )}
            </main>
        </>
    )
}
