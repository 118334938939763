import { Icon, Text } from '@fluentui/react'
import React from 'react'
import classNames from 'classnames'
import styles from 'components/containers/card/card.module.scss'
import { Divider } from 'components/elements'
import type { IIconProps } from '@fluentui/react'

export type CardProps = {
    /** Ttile */
    title?: string | React.ReactElement
    /** Iconname */
    iconName?: IIconProps['iconName']
    /** Classname */
    className?: string
    /** Children */
    children: React.ReactNode
}

/**
 * Card item
 */
export default function Card({ title = '', iconName = '', className = '', children }: CardProps): React.ReactElement {
    return (
        <div className={classNames(styles.card, className)}>
            {title && (
                <>
                    <Text
                        variant="large"
                        block
                    >
                        <Icon iconName={iconName} /> {title}
                    </Text>
                    <Divider />
                </>
            )}
            {children}
        </div>
    )
}
