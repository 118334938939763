import { Text } from '@fluentui/react'
import styles from 'pages/index/components/tabs/validation/components/tips/tips.module.scss'

/**
 * Tips
 */
export default function Tips(): React.ReactElement {
    return (
        <Text className={styles.tips}>
            <ul>
                <li>
                    <b>Pour les missions, de maniere générale, toujours respecter le format :</b>
                    <ul>
                        <li>Date - Date</li>
                        <li>Titre</li>
                        <li>NOM DU CLIENT - Description du client</li>
                        <li>Projet: le nom du projet</li>
                        <li>Contenu: ...</li>
                        <li>Outils: liste des outils sépérés par des virgules</li>
                    </ul>
                </li>
                <li>
                    <b>Pour les missions, si la première affichée a des résultats bizarres :</b>
                    <ul>
                        <li>
                            Faites un "CTRL + Entrée" entre la première page et la seconde afin de bien séparer la première page des
                            missions.
                        </li>
                        <li>
                            Vérifiez qu'il n'y a pas de nombres/chiffres ou mois en début de ligne présents sur la première page. Si oui,
                            essayez de les faire précéder par un autre mot.
                        </li>
                    </ul>
                </li>
                <li>
                    <b>Pour les secteurs, formations, certifications et outils et langages :</b>
                    <ul>
                        <li>
                            En cas de décalage (ex: les outils et langages se retrouvent dans les secteurs), une solution est de déplacer
                            légèrement (sur la droite ou la gauche) l'encadré blanc contenant divers textes (Outils et langages, etc.) de la
                            première page. Cette solution peut corriger l'alignement des éléments et donc la récupération des données.
                        </li>
                        <li>
                            Une solution de dernier recours peut être de re faire votre CV proprement à partir du template d'origine en
                            faisant bien attention à ne pas re copier/coller des éléments de mise en page, mais uniquement du texte.
                        </li>
                    </ul>
                </li>
                <li>
                    <b>Outils et langages</b>
                    <ul>
                        <li>
                            En cas de décalage entre la catégories et les outils, bien penser à faire des "MAJ + Entrée" putôt que des
                            "Entrée"
                        </li>
                    </ul>
                </li>
            </ul>
        </Text>
    )
}
