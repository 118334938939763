import { useEffect } from 'react'

/**
 * Use component did mount
 * {@link https://www.30secondsofcode.org/react/s/use-component-did-mount}
 * @param callback Callback
 */
export default function useComponentDidMount(callback: () => void): void {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => callback(), [])
}
