import { PureComponent } from 'react'
import classNames from 'classnames'
import styles from 'pages/_error-boundary/error-boundary.module.scss'
import env from 'types/env'
import IconPng from 'assets/img/logo.png'
import { Card } from 'components/containers'

export type ErrorBoundaryProps = {
    /** Children */
    children: React.ReactNode
}

export type ErrorBoundaryState = {
    /** Error */
    error?: Error
    /** Stack */
    stack?: Error['stack']
}

/**
 * ErrorBoundary
 */
export default class ErrorBoundary extends PureComponent<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props)
        this.state = {
            error: undefined,
            stack: undefined,
        }
    }

    /**
     * GetDerivedStateFromError
     * @param error Error
     */
    static getDerivedStateFromError(error: Error) {
        return {
            error,
            stack: (() => {
                try {
                    const split = error.stack?.split('\n') ?? []
                    if (split?.length > 1) {
                        return split?.slice(1)?.join('\n')
                    }
                    return error.stack
                } catch (e) {
                    return error.stack
                }
            })(),
        }
    }

    /**
     * Render
     */
    render() {
        const { error, stack } = this.state
        const { children } = this.props

        if (error) {
            return (
                <main className={classNames(styles['error-boundary'])}>
                    <div className={classNames(styles['top-area'])} />
                    <div className={classNames(styles['bottom-area'])} />
                    <div className={classNames(styles['left-area'])} />
                    <div className={classNames(styles['right-area'])} />
                    <div className={classNames(styles['main-area'])}>
                        <Card>
                            <img
                                src={IconPng}
                                alt="template business app"
                            />
                            <p>
                                {}
                                {env.MODE !== 'development' &&
                                    // eslint-disable-next-line max-len
                                    "Une erreur s'est produite, veuillez contacter l'équipe de développement avec : une capture d'écran de cette page et des détails sur ce qui vous a amené ici."}
                                {env.MODE === 'development' && 'Uncaught Error'}
                            </p>
                            <p>
                                {error.name}: {error.message}
                            </p>
                            <p className={classNames(styles.stack)}>{stack}</p>
                        </Card>
                    </div>
                </main>
            )
        }

        return children
    }
}
