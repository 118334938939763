import { useCallback } from 'react'
import { IS_MULTI_SELECT_CLASSNAME } from 'components/inputs/dropdown/dropdown.component'
import { getUpdatedListByKey } from 'utils/others/get-updated-list'
import type { IComboBoxOption, IDropdownOption } from '@fluentui/react'

export type EventType =
    | React.FormEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement | HTMLDivElement | HTMLElement>
    | React.MouseEvent<HTMLElement, MouseEvent>
    | undefined

export type UseHandleChangeReturns = {
    /** HandleChange */
    handleChange: (ev: EventType, option?: string | IComboBoxOption | boolean) => void
}

/**
 * UseHandleChange
 * @param action Action
 * @param type Type to instanciate to
 */
export default function useHandleChange<T>(action: React.Dispatch<React.SetStateAction<T>>, type?: unknown): UseHandleChangeReturns {
    const updateObjProp = useCallback(
        /**
         * UpdateObjProp
         * @param obj Object to update
         * @param val Value to set
         * @param path Property path to update, ex: abc.def.ghi
         * @param isDropDownMultiSelect If it's a dropdown, is is a multiselect?
         */
        (obj: T, val: unknown, path: string[], isDropDownMultiSelect = false): T => {
            const [head, ...rest] = path

            if (rest.length === 0) {
                if (isDropDownMultiSelect) {
                    // eslint-disable-next-line no-param-reassign, @typescript-eslint/no-explicit-any
                    ;(obj as any)[head] = getUpdatedListByKey((obj as any)[head], val as number)
                } else {
                    // eslint-disable-next-line no-param-reassign, @typescript-eslint/no-explicit-any
                    ;(obj as any)[head] = val
                }
            } else {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                updateObjProp((obj as any)[head], val, rest)
            }
            return obj
        },
        [],
    )

    const handleChange = useCallback(
        /**
         * HandleChange
         * @param ev Event
         * @param option Option
         */
        (ev: EventType, option?: string | IComboBoxOption | boolean) => {
            const name =
                (
                    (ev?.target as HTMLInputElement)?.name || // Classic input
                    (ev?.target as HTMLElement)?.getAttribute('name') || // Dropdown
                    ((ev?.target as HTMLElement)?.querySelector('span > span > span') as HTMLInputElement)?.getAttribute('name') || // Combobox
                    (ev?.target as HTMLElement)?.parentElement?.getAttribute('name')
                ) // Toggle
                    ?.split('.') ?? []

            if (!name || name.length === 0 || name[0] === '') {
                throw new Error("Please provide a 'name' props to the component")
            }

            const value = (() => {
                if (
                    (ev?.target as HTMLDivElement)?.classList?.contains('ms-Dropdown') ||
                    (ev?.target as HTMLDivElement)?.classList?.contains('ms-ComboBox-option') ||
                    (ev?.target as HTMLDivElement)?.closest?.('.ms-ComboBox-option')
                ) {
                    return (option as IDropdownOption)?.key
                }
                if (
                    (ev?.target as HTMLDivElement)?.classList?.contains('ms-Toggle-background') ||
                    (ev?.target as HTMLDivElement)?.closest?.('.ms-Toggle-background')
                ) {
                    return option as boolean
                }
                if (ev?.target instanceof HTMLTextAreaElement) {
                    return ev?.target.value
                }
                if (ev?.target instanceof HTMLInputElement) {
                    switch (ev?.target?.type) {
                        case 'number':
                            return ev?.target.valueAsNumber
                        case 'date':
                            return ev?.target.valueAsDate
                        case 'checkbox':
                            return ev?.target.checked
                        default:
                            return ev?.target.value
                    }
                }
                return null
            })()

            action(prev =>
                updateObjProp(
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    type ? new (type as any)(structuredClone(prev)) : structuredClone(prev),
                    value,
                    name,
                    ((ev?.target as HTMLDivElement)?.classList?.contains('ms-Dropdown') &&
                        (ev?.target as HTMLDivElement).parentElement?.classList?.contains(IS_MULTI_SELECT_CLASSNAME)) || // Dropdown
                        ((ev?.target as HTMLDivElement).parentElement?.classList?.contains('ms-ComboBox-option') &&
                            (ev?.target as HTMLInputElement)?.type === 'checkbox'), // Combobox
                ),
            )
        },
        [action, updateObjProp, type],
    )

    return {
        handleChange,
    }
}
