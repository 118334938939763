import { useJsApiLoader } from '@react-google-maps/api'
import { useMemo } from 'react'
import { useHandleException, useMediaQueries } from 'hooks'
import { useGetMapCvs } from 'requests/hooks/cvs.hook'
import { useSelector } from 'store'
import env from 'types/env'
import { RESOLVED } from 'types/status'
import type { UseMediaQueriesReturns } from 'hooks/media-queries.hook'
import type { StatusType } from 'types/status'
import type { Cv } from 'requests/models/objects'

export type UseMapReturns = {
    /** IsMapLoaded */
    isMapLoaded: boolean
    /** Items */
    items: { [key: string]: Cv[] }
    /** Status */
    status?: StatusType
    /** Results */
    results: string
} & Pick<UseMediaQueriesReturns, 'isTablet'>

/**
 * UseMapPage
 */
export default function useMap(): UseMapReturns {
    const { handleException } = useHandleException()
    const query = useSelector(state => state.common.query)
    const {isTablet} = useMediaQueries()

    const { isLoaded: isMapLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: env.GOOGLE_MAPS_API_KEY,
    })

    const { data, status } = useGetMapCvs(
        { ...query },
        {
            enabled: query.q !== null,
            onError(err) {
                handleException(err)
            },
        },
    )

    /** Computed values */
    const items = useMemo(() => {
        /** Grouped data by agency */
        const grouped =
            data?.reduce(
                (prev, curr) => ({
                    ...prev,
                    [curr.agency]: prev[curr.agency] ? [...prev[curr.agency], curr] : [curr],
                }),
                {} as UseMapReturns['items'],
            ) ?? {}

        // Sort by key
        return Object.keys(grouped)
            .sort()
            .reduce((obj, key) => {
                // eslint-disable-next-line no-param-reassign
                obj[key] = grouped[key]
                return obj
            }, {} as UseMapReturns['items'])
    }, [data])

    const results = useMemo(
        () => (status === RESOLVED ? `${new Intl.NumberFormat('fr-fr').format(data?.length || 0)} résultats` : ''),
        [data?.length, status],
    )

    return {
        isMapLoaded,
        items,
        status,
        results,
        isTablet
    }
}
