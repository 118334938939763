import useIndex from 'pages/index/index.hook'
import All from 'pages/index/components/tabs/all/all.component'
import Availability from 'pages/index/components/tabs/availability/availability.component'
import Errors from 'pages/index/components/tabs/errors/errors.component'
import Map from 'pages/index/components/tabs/map/map.component'
import Skills from 'pages/index/components/tabs/skills/skills.component'
import Validation from 'pages/index/components/tabs/validation/validation.component'
import Synonyms from 'pages/index/components/tabs/synonyms/synonyms.component'
import NotFound from 'pages/index/components/tabs/not-found/not-found.component'

/**
 * Index page
 */
export default function Index(): React.ReactElement {
    const { hash } = useIndex()

    switch (hash) {
        case '':
        case '#all':
            return <All />
        case '#map':
            return <Map />
        case '#skills':
            return <Skills />
        case '#availability':
            return <Availability />
        case '#validation':
            return <Validation />
        case '#errors':
            return <Errors />
        case '#synonyms':
            return <Synonyms />
        case '#not-found':
            return <NotFound />
        default:
            return <div>Onglet non valide</div>
    }
}
