import classNames from 'classnames'
import { Helmet as Head } from 'react-helmet'
import { PrimaryButton, Text, TextField } from '@fluentui/react'
import useSynonyms from 'pages/index/components/tabs/synonyms/synonyms.hook'
import styles from 'pages/index/components/tabs/synonyms/synonyms.module.scss'
import { PENDING, REJECTED, RESOLVED } from 'types/status'
import { Loader } from 'components/elements'

/**
 * Synonyms page
 */
export default function Synonyms(): React.ReactElement {
    const { item, status, exception, onSubmit, handleChange, meProfile } = useSynonyms()

    return (
        <>
            <Head>
                <title>Synonymes - Nextoo</title>
            </Head>

            <main className={classNames(styles.synonyms)}>
                <form onSubmit={onSubmit}>
                    <Text>
                        <b>Ici, vous pouvez {meProfile === 'admin' ? 'mettre à jour' : 'consulter'} la liste des synonymes à utiliser.</b>
                        <ul>
                            <li>Chaque ligne correspond à un ensemble de synonymes.</li>
                            <li>Chaque expression est séparée par une virgule.</li>
                        </ul>
                        <br />
                        <b>Les synonymes sont également utilisés pour la génération de la Matrice de Compétences.</b>
                        <ul>
                            <li>
                                Chaque ligne commençant par un <b>#</b> correspond à une catégorie, les éléments suivants appartiennent à la
                                catégorie en question.
                            </li>
                            <li>
                                La ligne <b># Autre</b> (et les suivantes) n'apparaîtront pas dans la matrice.
                            </li>
                            <li>Les changements seront pris en compte à la prochaine synchronisation.</li>
                        </ul>
                        {meProfile === 'admin' && (
                            <>
                                <br />
                                <b>
                                    {}
                                    ⚠️ Attention, en enregistrant la liste, le moteur de recherche sera automatiquement désactivé un court
                                    instant afin de mettre à jour les synonymes.
                                </b>
                            </>
                        )}
                        <br />
                        <br />
                    </Text>
                    {status === PENDING && <Loader />}
                    {[RESOLVED, REJECTED].includes(status) && (
                        <>
                            <TextField
                                label="Synonymes"
                                placeholder="Synonymes"
                                name="synonyms"
                                value={item as string}
                                onChange={handleChange}
                                multiline
                                autoAdjustHeight
                                errorMessage={exception?.invalidFields?.synonyms}
                                required
                                onRenderLabel={() => null}
                                spellCheck="false"
                                readOnly={meProfile !== 'admin'}
                            />
                            {meProfile === 'admin' && (
                                <>
                                    <br />
                                    <PrimaryButton
                                        text="Enregistrer"
                                        type="submit"
                                        iconProps={{ iconName: 'Save' }}
                                    />
                                </>
                            )}
                        </>
                    )}
                </form>
            </main>
        </>
    )
}
