import { useMutation, useQuery } from 'react-query'
import { getCancelToken } from 'requests/fetch'
import {
    getAllCvs,
    getAvailabilityCvs,
    getErrorCvs,
    getMapCvs,
    getSkillsCvs,
    getSkillsFileCvs,
    getSynchroDate,
    postValidationCv,
} from 'requests/handlers/cvs.handler'
import { QUERY_CANCELLED } from 'types/messages'
import type { Exception } from 'requests/models/exceptions'
import type { ErrorParamsType, SearchParamsType } from 'requests/handlers/cvs.handler'
import type { Cv } from 'requests/models/objects'
import type { CvErrorFieldResultType, CvsSynchroDateResultType } from 'requests/types/results/cv.type'
import type { UseMutationOptions, UseQueryOptions } from 'react-query'

/**
 * UseGetCvs
 * @param params params
 * @param options options
 */
export function useGetAllCvs(
    params: SearchParamsType & {
        /** Date, used just to force the update of parameters each time, so new request is triggered */
        date: Date | null
    },
    options?: UseQueryOptions<Cv[], Exception<CvErrorFieldResultType>>,
) {
    const cancelToken = getCancelToken()

    return useQuery<Cv[], Exception<CvErrorFieldResultType>>(
        ['cvs', 'all', JSON.stringify(params)],
        () => {
            const promise = getAllCvs({ params: { q: params.q }, cancelToken })

            promise.cancel = () => cancelToken.cancel(QUERY_CANCELLED)

            return promise
        },
        options,
    )
}

/**
 * UseGetCvs
 * @param params params
 * @param options options
 */
export function useGetMapCvs(
    params: SearchParamsType & {
        /** Date, used just to force the update of parameters each time, so new request is triggered */
        date: Date | null
    },
    options?: UseQueryOptions<Cv[], Exception<CvErrorFieldResultType>>,
) {
    const cancelToken = getCancelToken()

    return useQuery<Cv[], Exception<CvErrorFieldResultType>>(
        ['cvs', 'map', JSON.stringify(params)],
        () => {
            const promise = getMapCvs({ params: { q: params.q }, cancelToken })

            promise.cancel = () => cancelToken.cancel(QUERY_CANCELLED)

            return promise
        },
        options,
    )
}

/**
 * UseGetSkillsCvs
 * @param params params
 * @param options options
 */
export function useGetSkillsCvs(
    params: SearchParamsType & {
        /** Date, used just to force the update of parameters each time, so new request is triggered */
        date: Date | null
    },
    options?: UseQueryOptions<Cv[], Exception<CvErrorFieldResultType>>,
) {
    const cancelToken = getCancelToken()

    return useQuery<Cv[], Exception<CvErrorFieldResultType>>(
        ['cvs', 'skills', JSON.stringify(params)],
        () => {
            const promise = getSkillsCvs({ params: { q: params.q }, cancelToken })

            promise.cancel = () => cancelToken.cancel(QUERY_CANCELLED)

            return promise
        },
        options,
    )
}

/**
 * UseGetSkillsFileCvs
 * @param params params
 * @param options options
 */
export function useGetSkillsFileCvs(
    params: SearchParamsType & {
        /** Date, used just to force the update of parameters each time, so new request is triggered */
        date: Date | null
    },
    options?: UseQueryOptions<Blob, Exception<CvErrorFieldResultType>>,
) {
    const cancelToken = getCancelToken()

    return useQuery<Blob, Exception<CvErrorFieldResultType>>(
        ['cvs', 'skills', 'file', JSON.stringify(params)],
        () => {
            const promise = getSkillsFileCvs({ params: { q: params.q }, cancelToken })

            promise.cancel = () => cancelToken.cancel(QUERY_CANCELLED)

            return promise
        },
        options,
    )
}

/**
 * UseGetAvailabilityCvs
 * @param params params
 * @param options options
 */
export function useGetAvailabilityCvs(
    params: SearchParamsType & {
        /** Date, used just to force the update of parameters each time, so new request is triggered */
        date: Date | null
    },
    options?: UseQueryOptions<Cv[], Exception<CvErrorFieldResultType>>,
) {
    const cancelToken = getCancelToken()

    return useQuery<Cv[], Exception<CvErrorFieldResultType>>(
        ['cvs', 'availability', JSON.stringify(params)],
        () => {
            const promise = getAvailabilityCvs({ params: { q: params.q }, cancelToken })

            promise.cancel = () => cancelToken.cancel(QUERY_CANCELLED)

            return promise
        },
        options,
    )
}

/**
 * UsePostValidationCv
 * @param file File
 * @param options options
 */
export function usePostValidationCv(
    options?: UseMutationOptions<
        Cv,
        Exception<CvErrorFieldResultType>,
        {
            /** File */
            file: globalThis.File
        }
    >,
) {
    const cancelToken = getCancelToken()

    return useMutation<
        Cv,
        Exception<CvErrorFieldResultType>,
        {
            /** File */
            file: globalThis.File
        }
    >(
        ['cvs', 'validation'],
        ({ file }) => {
            const promise = postValidationCv({ file, cancelToken })

            promise.cancel = () => cancelToken.cancel(QUERY_CANCELLED)

            return promise
        },
        options,
    )
}

/**
 * UseGetCvs
 * @param params params
 * @param options options
 */
export function useGetErrorCvs(params: ErrorParamsType, options?: UseQueryOptions<Cv[], Exception<CvErrorFieldResultType>>) {
    const cancelToken = getCancelToken()

    return useQuery<Cv[], Exception<CvErrorFieldResultType>>(
        ['cvs', 'error'],
        () => {
            const promise = getErrorCvs({ cancelToken, params })

            promise.cancel = () => cancelToken.cancel(QUERY_CANCELLED)

            return promise
        },
        options,
    )
}

/**
 * UseGetSynchroDate
 * @param options options
 */
export function useGetLastSynchro(options?: UseQueryOptions<Date, Exception<CvsSynchroDateResultType>>) {
    const cancelToken = getCancelToken()

    return useQuery<Date, Exception<CvsSynchroDateResultType>>(
        ['cvs', 'last-synchro'],
        () => {
            const promise = getSynchroDate({ cancelToken })

            promise.cancel = () => cancelToken.cancel(QUERY_CANCELLED)

            return promise
        },
        options,
    )
}
